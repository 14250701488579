import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar/SideBar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../context/context";
import getPackagesDetails from "../../services/packages/getPackages";
import { USER_DETAILS } from "../../constants/localstorageKeys";
import { toast } from "react-toastify";
import DataTables from "../../component/DataTable";
import { useNavigate } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { images } from "../../utils/image";

const MyPackage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentLocalization, setToastId } = useContext(AuthUserContext);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [sortedUsers, setSortedUsers] = useState([]);
  const columns = [
    {
      name: <span className="dataTable-text">{t("table.vendor")}</span>,
      key: "vendorType",
      cell: (row) => (
        <div className="text-start column-text d-flex align-items-center">
          <div className="" style={{ width: "55px" }}>
            <img
              src={
                row?.vendorType == "esimgo"
                  ? images.esimgo
                  : row?.vendorType === "esimVault"
                    ? images.esimVault
                    : images.airalo
              }
              alt="offer-icon"
              width={
                row?.vendorType == "esimgo"
                  ? 35
                  : row?.vendorType == "esimVault"
                    ? 50
                    : 50
              }
            />
          </div>
          <span className="ms-2">{row?.vendorType}</span>
        </div>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: <span className="dataTable-text">{t("table.simid")}</span>,
      key: "sims.0.iccid",
      selector: (row) => (
        <span className="column-text">{row?.sims[0]?.iccid}</span>
      ),
      sortable: true,
      width: "250px",
    },
    {
      name: <span className="dataTable-text">{t("table.username")}</span>,
      key: "userId.name",
      cell: (row) => (
        <span className="text-start column-text">{row?.userId?.name}</span>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="dataTable-text">{t("table.packageName")}</span>,
      key: "package",
      selector: (row) => row?.package,
      cell: (row) => (
        <span className="text-start column-text">
          {row.package && row.package?.length > 35
            ? `${row.package.slice(0, 35)}...`
            : row.package}
        </span>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="dataTable-text"> {t("table.expiration")}</span>,
      // key: "created_at",
      selector: (row) => (
        <span className="column-text">
          {new Date(
            new Date(row?.created_at).getTime() +
            row?.validity * 24 * 60 * 60 * 1000
          ).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </span>
      ),
      width: "180px",
    },
    {
      name: <span className="dataTable-text">{t("table.validDays")}</span>,
      key: "validity",
      selector: (row) => (
        <span className="column-text">
          {row?.validity} {t("home.part21")}
        </span>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: <span className="dataTable-text">{t("table.Data")}</span>,
      key: "data",
      selector: (row) => (
        <span className="column-text">
          {row?.data?.replace("GB", "").trim()} {t("home.part22")}
        </span>
      ),
      sortable: true,
      width: "90px",
    },
    {
      name: <span className="dataTable-text">{t("table.orignalPrice")}</span>,
      key: "discountedPrice",
      selector: (row) => (
        <span className="text-start column-text">
          ${row.event === 'topup' ? parseFloat(row?.price / 100).toFixed(2) : parseFloat(row?.price).toFixed(2)}
        </span>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: <span className="dataTable-text">{t("table.Discount")}</span>,
      key: "discountedPer",
      selector: (row) => (
        <span className="text-start column-text">
          {row.discountedPer ? row.discountedPer + "%" : '-'}
        </span>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: <span className="dataTable-text">{t("table.Price")}</span>,
      key: "price",
      selector: (row) => (
        <span className="text-start column-text">
          ${(row?.discountedPrice ? parseFloat(row?.discountedPrice).toFixed(2)
            : parseFloat(row?.price).toFixed(2))}
        </span>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: <span className="dataTable-text">{t("table.Status")}</span>,
      selector: (row) => (
        <h5>
          <Badge
            bg={
              new Date() - new Date(row?.created_at) <=
                row.validity * 24 * 60 * 60 * 1000
                ? "success"
                : "danger"
            }
          >
            {new Date() - new Date(row?.created_at) <=
              row?.validity * 24 * 60 * 60 * 1000
              ? "Active"
              : "Expire"}
          </Badge>
        </h5>
      ),
      width: "120px",
    },
  ];
  const handleSort = async (column, direction) => {
    const nestedSort = (obj, path) => {
      const value = path
        .split(".")
        .reduce(
          (value, key) =>
            value && value[key] !== undefined ? value[key] : null,
          obj
        );
      if (path === "data") {
        return parseFloat(value.replace("GB", "").trim());
      }

      return value;
    };

    const sortedData = [...packages].sort((a, b) => {
      const aValue = nestedSort(a, column.key);
      const bValue = nestedSort(b, column.key);

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setSortedUsers(sortedData);
  };
  const getPackages = async () => {
    try {
      const response = await getPackagesDetails();
      setPackages(response?.data);
      setSortedUsers(response?.data);
    } catch (err) {
      if (err.response?.status == 401) {
        const id = toast.error(t("toastsMessage.part4"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        window.scroll(0, 0);
        localStorage.clear();
        navigate("/log-in");
      } else {
        console.log("err", err);
        const id = toast.error(err.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPackages();
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
    if (userDetails) {
      setUser(userDetails);
    }
  }, [currentLocalization]);
  return (
    <Sidebar>
      <Container>
        <Row>
          <Col>
            <div
              className={
                currentLocalization == "ar" ? "text-end" : "text-start"
              }
            >
              <h2 className="package-h2">
                {t(
                  user && user.role === "user"
                    ? "dataUsage.myPackage"
                    : "dataUsage.sim"
                )}
              </h2>
            </div>
            <div className="mt-4">
              <DataTables
                columns={columns}
                data={sortedUsers.length ? sortedUsers : packages}
                loading={loading}
                handleSort={handleSort}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Sidebar>
  );
};

export default MyPackage;
