import axios from "axios";
import routes from "../../../configs/api";

const getEsimLists = (price,days, data, countriesParam,region, lang) => {
  return new Promise((resolve, reject) => {
    const URL = `${routes.ESIM_LIST}?price=${price}&days=${days}&data=${data}&${countriesParam}&region=${region}&lang=${lang}`;
    axios
      .get(URL)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getEsimLists;
