import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const SectionMode = ({ allSectionWithLang }) => {
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);

  const [expandedSections, setExpandedSections] = useState(
    allSectionWithLang.reduce((acc, section) => {
      acc[section._id] = false; // Initialize all sections as collapsed
      return acc;
    }, {})
  );

  const toggleSection = (id) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <>
      {allSectionWithLang.map((section, index) => (
        <Container key={section._id}>
          <Row
            className={`d-flex justify-content-center align-items-center pt-5 pb-5 ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
              }`}
          >
            {/* Odd index: Text left, Image right */}
            {index % 2 === 0 ? (
              <>
                <Col md={7} sm={12} className="text-start mt-3">
                  <h2 className="mode-h2">{section.title}</h2>
                  <p
                    className={`mode-p mb-0 ${expandedSections[section._id] ? "mode-p-expanded" : "mode-p-collapsed"}`}
                    dangerouslySetInnerHTML={{
                      __html: expandedSections[section._id]
                        ? section.description
                        : `${section.description.substring(0, 500)}${section.description.length > 500 ? '...' : ''}`,
                    }}
                  ></p>
                  {section.description.length > 500 && (
                    <button
                      onClick={() => toggleSection(section._id)}
                      className="btn btn-primary d-block mx-auto"
                      style={{ backgroundColor: '#165960', borderColor: '#165960' }}
                    >
                      {expandedSections[section._id] ? (
                        <>
                          {t('Show Less')} <FaArrowUp size={16} style={{ verticalAlign: 'middle' }} />
                        </>
                      ) : (
                        <>
                          {t('Show More')} <FaArrowDown size={16} style={{ verticalAlign: 'middle' }} />
                        </>
                      )}
                    </button>
                  )}
                </Col>
                <Col
                  md={5}
                  sm={12}
                  className={`mt-3 ${currentLocalization === "ar" ? "text-start" : "text-end"
                    } `}
                >
                  <img
                    src={section.image}
                    alt={section.title}
                    className="section-image"
                    
                  />
                </Col>
              </>
            ) : (
              /* Even index: Image left, Text right */
              <>
                <Col
                  md={5}
                  sm={12}
                  className={`mt-3 ${currentLocalization === "ar" ? "text-end" : "text-start"
                    } `}
                >
                  <img
                    src={section.image}
                    alt={section.title}
                     className="section-image"
                    
                  />
                </Col>
                <Col md={7} sm={12} className="text-start mt-3">
                  <h2 className="mode-h2">{section.title}</h2>
                  <p
                    className={`mode-p mb-0 ${expandedSections[section._id] ? "mode-p-expanded" : "mode-p-collapsed"}`}
                    dangerouslySetInnerHTML={{
                      __html: expandedSections[section._id]
                        ? section.description
                        : `${section.description.substring(0, 500)}${section.description.length > 500 ? '...' : ''}`,
                    }}
                  ></p>
                  {section.description.length > 500 && (
                    <button
                      onClick={() => toggleSection(section._id)}
                      className="btn btn-primary d-block mx-auto"
                      style={{ backgroundColor: '#165960', borderColor: '#165960' }}
                    >
                      {expandedSections[section._id] ? (
                        <>
                          {t('Show Less')} <FaArrowUp size={16} style={{ verticalAlign: 'middle' }} />
                        </>
                      ) : (
                        <>
                          {t('Show More')} <FaArrowDown size={16} style={{ verticalAlign: 'middle' }} />
                        </>
                      )}
                    </button>
                  )}
                </Col>
              </>
            )}
          </Row>
        </Container>
      ))}
    </>
  );
};

export default SectionMode;


// import React, { useContext, useState } from "react";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import { useTranslation } from "react-i18next";
// import { AuthUserContext } from "../context/context";
// import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

// const SectionMode = ({ allSectionWithLang }) => {
//   const { t } = useTranslation();
//   const { currentLocalization } = useContext(AuthUserContext);

//   const [expandedSections, setExpandedSections] = useState(
//     allSectionWithLang.reduce((acc, section) => {
//       acc[section._id] = false; // Initialize all sections as collapsed
//       return acc;
//     }, {})
//   );

//   const toggleSection = (id) => {
//     setExpandedSections((prevState) => ({
//       ...prevState,
//       [id]: !prevState[id],
//     }));
//   };

//   return (
//     <>
//       {allSectionWithLang.map((section, index) => (
//         <Container key={section._id}>
//           <Row
//             className={`d-flex justify-content-center align-items-center pt-5 pb-5 ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
//               }`}
//           >
//             {/* Odd index: Text left, Image right */}
//             {index % 2 === 0 ? (
//               <>
//                 <Col md={7} sm={12} className="text-start mt-3">
//                   <h2 className="mode-h2">{section.title}</h2>
//                   <p
//                     className={`mode-p border ${expandedSections[section._id] ? "mode-p-expanded" : "mode-p-collapsed"}`}
//                     dangerouslySetInnerHTML={{
//                       __html: section.description,
//                     }}
//                   ></p>
//                   {section.description.length > 500 && (
//                     <button
//                       onClick={() => toggleSection(section._id)}
//                       className="btn btn-primary d-block mx-auto mt-3"
//                       style={{ backgroundColor: '#165960', borderColor: '#165960' }}
//                     >
//                       {expandedSections[section._id] ? (
//                         <>
//                           {t('Show Less')} <FaArrowUp size={16} style={{ verticalAlign: 'middle' }} />
//                         </>
//                       ) : (
//                         <>
//                           {t('Show More')} <FaArrowDown size={16} style={{ verticalAlign: 'middle' }} />
//                         </>
//                       )}
//                     </button>
//                   )}
//                 </Col>
//                 <Col
//                   md={5}
//                   sm={12}
//                   className={`mt-3 ${currentLocalization === "ar" ? "text-start" : "text-end"
//                     } `}
//                 >
//                   <img
//                     src={section.image}
//                     alt={section.title}
//                     style={{
//                       maxWidth: "510px",
//                       width: "100%",
//                       height: "510px",
//                       borderRadius: "10px",
//                       border: "1px solid #054940",
//                     }}
//                   />
//                 </Col>
//               </>
//             ) : (
//               /* Even index: Image left, Text right */
//               <>
//                 <Col
//                   md={5}
//                   sm={12}
//                   className={`mt-3 ${currentLocalization === "ar" ? "text-end" : "text-start"
//                     } `}
//                 >
//                   <img
//                     src={section.image}
//                     alt={section.title}
//                     style={{
//                       maxWidth: "510px",
//                       width: "100%",
//                       height: "510px",
//                       borderRadius: "10px",
//                       border: "1px solid #054940",
//                     }}
//                   />
//                 </Col>
//                 <Col md={7} sm={12} className="text-start mt-3">
//                   <h2 className="mode-h2">{section.title}</h2>
//                   <p
//                     className={`mode-p border ${expandedSections[section._id] ? "mode-p-expanded" : "mode-p-collapsed"}`}
//                     dangerouslySetInnerHTML={{
//                       __html: section.description,
//                     }}
//                   ></p>
//                   {section.description.length > 500 && (
//                     <button
//                       onClick={() => toggleSection(section._id)}
//                       className="btn btn-primary d-block mx-auto mt-3"
//                       style={{ backgroundColor: '#165960', borderColor: '#165960' }}
//                     >
//                       {expandedSections[section._id] ? (
//                         <>
//                           {t('Show Less')} <FaArrowUp size={16} style={{ verticalAlign: 'middle' }} />
//                         </>
//                       ) : (
//                         <>
//                           {t('Show More')} <FaArrowDown size={16} style={{ verticalAlign: 'middle' }} />
//                         </>
//                       )}
//                     </button>
//                   )}
//                 </Col>
//               </>
//             )}
//           </Row>
//         </Container>
//       ))}
//     </>
//   );
// };

// export default SectionMode;
