import axios from "axios";
import routes from "../../configs/api";
import { AUTH_TOKEN_KEY } from "../../constants/localstorageKeys";

const addSimLabel = (data) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    const URL = routes.ADD_LABEL;
    axios
      .post(URL, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default addSimLabel;
