import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../../component/Sidebar/SideBar";
import { Container, Row, Col } from "react-bootstrap";
import ButtonGroups from "../../component/ButtonGroup";
import deleteSection from "../../assets/images/delete-section.svg";
import TextEditor from "../../component/TextEditor";
import upload from "../../assets/images/upload.svg";
import createSection from "../../services/contentManagement/createSections";
import { toast } from "react-toastify";
import { AuthUserContext } from "../../context/context";
import getSection from "../../services/contentManagement/getSection";
import Spinner from "react-bootstrap/Spinner";
import refresh from "../../assets/images/refresh.svg";
import updateSection from "../../services/contentManagement/updateSections";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import deleteIcon from "../../assets/images/delete-icon.svg";
import deleteSections from "../../services/contentManagement/deleteSection";
import PreViewModal from "../../component/PreViewModal";
import getSectionWithLang from "../../services/contentManagement/getSectionWithLang";
import publishSections from "../../services/contentManagement/publishSections";
import { useTranslation } from "react-i18next";
const ContentManagement = () => {
  const [buttonTabs, setButtonTabs] = useState("Home");
  const { t } = useTranslation();
  const languages = ["English", "Arabic", "Indonesian", "French"];
  const [loading, setLoading] = useState(true);
  const [inputRefs, setInputRefs] = useState({});
  const [newSectionAdded, setNewSectionAdded] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [sectionId, setSectionId] = useState("");
  const [preModeModalShow, setPreModeModalShow] = useState(false);
  const [preModeModalLoading, setPreModeModalLoading] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [allSectionWithLang, setAllSetSectionWithLang] = useState([])
  const { currentLocalization } = useContext(AuthUserContext);
  const [sections, setSections] = useState([
    {
      id: Date.now(),
      language: "English",
      content: {
        English: { title: "", description: "" },
        Arabic: { title: "", description: "" },
        Indonesian: { title: "", description: "" },
        French: { title: "", description: "" },
      },
      imageSrc: null,
      imageUrl: null,
    },
  ]);
  const { setToastId } = useContext(AuthUserContext);
  const [loadingSections, setLoadingSections] = useState({});
  const [isValid, setIsValid] = useState({});
  const [validationMessages, setValidationMessages] = useState({});
  const [previewModeCheck, setPreviewModeCheck] = useState(false)
  const handleTitleChange = (sectionId, language, title) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId
          ? {
            ...section,
            content: {
              ...section.content,
              [language]: {
                ...section.content[language],
                title,
              },
            },
          }
          : section
      )
    );
  };

  const handleDescriptionChange = (sectionId, language, description) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId
          ? {
            ...section,
            content: {
              ...section.content,
              [language]: {
                ...section.content[language],
                description,
              },
            },
          }
          : section
      )
    );
  };

  const handleImageChange = (sectionId, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSections((prevSections) =>
          prevSections.map((section) =>
            section.id === sectionId
              ? {
                ...section,
                imageSrc: reader.result,
                imageUrl: file,
              }
              : section
          )
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddSection = () => {
    const newSectionId = Date.now();
    setSections((prevSections) => [
      ...prevSections,
      {
        id: newSectionId,
        language: "English",
        content: {
          English: { title: "", description: "" },
          Arabic: { title: "", description: "" },
          Indonesian: { title: "", description: "" },
          French: { title: "", description: "" },
        },
        imageSrc: null,
        imageUrl: null,
      },
    ]);

    setInputRefs((prevRefs) => ({
      ...prevRefs,
      [newSectionId]: React.createRef(),
    }));

    setNewSectionAdded(true);
  };

  const handlePublishClick = async () => {
    setIsPublishing(true);
    const ids = sections
      .filter(section => !(section.id && section.id.toString().length != 24))
      .map(section => section.id);

    let ToastId;
    try {
      const response = await publishSections(ids);
      setPreviewModeCheck(false);
      ToastId = toast.success(response.message, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      });
    } catch (error) {
      setIsPublishing(false);
      ToastId = toast.error('Failed to publish sections. Please try again.', {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      });
    } finally {
      setToastId(ToastId);
      setIsPublishing(false);
    }
  };


  const handleDeleteSection = (sectionId) => {
    setModalShow(true);
    setSectionId(sectionId);
  };
  const handleSaveSection = async (sectionId) => {
    const section = sections.find((section) => section.id === sectionId);
    const languages = ["English", "Indonesian", "French", "Arabic"];

    let isValidSection = true;
    let missingLanguages = [];
    let validationMessage = "";

    languages.forEach((lang) => {
      const title = section.content[lang]?.title.trim();
      const description = section.content[lang]?.description.trim();

      if (!title || !description) {
        isValidSection = false;
        missingLanguages.push(lang);
      }
    });

    if (missingLanguages.length > 0) {
      validationMessage = `Please fill in all required fields for the following languages: ${missingLanguages.join(
        ", "
      )}.`;
    }

    if (!section.imageSrc) {
      isValidSection = false;
      validationMessage += " Please upload an image.";
    }

    setIsValid((prevState) => ({
      ...prevState,
      [sectionId]: !isValidSection,
    }));

    setValidationMessages((prevMessages) => ({
      ...prevMessages,
      [sectionId]: validationMessage,
    }));

    if (!isValidSection) {
      return;
    }

    try {
      setLoadingSections((prevState) => ({
        ...prevState,
        [sectionId]: true,
      }));

      const formData = new FormData();
      languages.forEach((lang) => {
        const langKey = lang.toLowerCase().substring(0, 2);
        formData.append(`title[${langKey}]`, section.content[lang].title);
        formData.append(
          `description[${langKey}]`,
          section.content[lang].description
        );
      });

      formData.append("key", buttonTabs);
      formData.append("image", section.imageUrl);

      let response;
      let toastId;
      const isUpdating = section.id && section.id.toString().length === 24;

      if (isUpdating) {
        response = await updateSection(formData, sectionId);
        toastId = toast.success(t("Section updated successfully!"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true,
        });
      } else {
        response = await createSection(formData);
        toastId = toast.success(t("Section saved successfully!"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true,
        });
      }
      setPreviewModeCheck(true)
      setToastId(toastId);
      // getAllSections();

      setSections((prevSections) =>
        prevSections.map((s) =>
          s.id === sectionId
            ? {
              ...s,
              id: response.data._id,
            }
            : s
        )
      );
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoadingSections((prevState) => ({
        ...prevState,
        [sectionId]: false,
      }));
    }
  };


  const handleLanguageChange = (sectionId, language) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId ? { ...section, language } : section
      )
    );
  };
  const getAllSections = async () => {
    try {
      setLoading(true);
      setPreviewModeCheck(false);
      let sectionArray = [];
      const response = await getSection(buttonTabs);
      if (response.data.length > 0) {
        for (const section of response.data) {
          if (section.mode === "previewMode") {
            setPreviewModeCheck(true);
          }
          const object = {
            id: section._id,
            language: "English",
            content: {
              English: {
                title: section?.title?.en,
                description: section?.description?.en,
              },
              Arabic: {
                title: section?.title?.ar,
                description: section?.description?.ar,
              },
              Indonesian: {
                title: section?.title?.in,
                description: section?.description?.in,
              },
              French: {
                title: section?.title?.fr,
                description: section?.description?.fr,
              },
            },
            imageSrc: section?.image,
          };
          sectionArray.push(object);
        }
        setSections(sectionArray);
      }
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteSectionWithId = async () => {
    try {
      const isUpdating = sectionId && sectionId.toString().length === 24;
      if (isUpdating) {
        if (sectionId) {
          setConfirmLoading(true);
          const response = await deleteSections(sectionId);
          if (response) {
            const toastId = toast.success(response.message, {
              autoClose: false,
              closeOnClick: false,
              draggable: false,
              pauseOnHover: true,
            });
            setToastId(toastId);
            setModalShow(false);
            setSections((prevSections) => {
              const updatedSections = prevSections.filter((section) => section.id !== sectionId);
              if (updatedSections.length === 0) {
                handleAddSection();
              }
              return updatedSections;
            });
          }
        }
      } else {
        setModalShow(false);
        setSections((prevSections) =>
          prevSections.filter((section) => section.id !== sectionId)
        );
        if (sections.length === 1) {
          handleAddSection();
        }
      }
    } catch (e) {
      console.log("e", e);
      toast.error(e);
    } finally {
      setConfirmLoading(false);
    }
  };
  const handlePreviewMode = async () => {
    try {
      setPreModeModalShow(true);
      let lang;
      if (currentLocalization.startsWith("en-")) {
        lang = "en";
      } else {
        lang = currentLocalization;
      }
      setPreModeModalLoading(true);
      const response = await getSectionWithLang(
        buttonTabs,
        lang,
        // "previewMode"
      );
      // console.log("response", response);
      setAllSetSectionWithLang(response?.data)
    } catch (e) {
      console.log("e", e);
    } finally {
      setPreModeModalLoading(false);
    }
  };
  useEffect(() => {
    if (newSectionAdded) {
      if (sections.length > 0) {
        const lastSection = sections[sections.length - 1];
        const ref = inputRefs[lastSection.id];
        if (ref) {
          ref.current.focus();
        }
      }
      setNewSectionAdded(false);
    }
  }, [newSectionAdded, sections, inputRefs]);

  useEffect(() => {
    getAllSections();
  }, [buttonTabs]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Sidebar>
        <Container style={{ marginTop: "50px" }}>
          <Row>
            <Col xs={12} className="p-0">
              <ButtonGroups
                setButtonTabs={setButtonTabs}
                buttonTabs={buttonTabs}
                setSections={setSections}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xl={3}
              lg={6}  md={7} xs={12} className="text-start p-0 mt-3">
              <h2 className="package-h2 m-0">{buttonTabs}</h2>
            </Col>
            <Col
              xs={12}
              xl={9}
              lg={12}
              md={12}
              className="d-flex flex-lg-row flex-column justify-content-lg-end pe-0 mt-3"
              style={{ gap: "12px" }}
            >
              <button className={!previewModeCheck ? "btn-preview-disable" : "btn-preview"} onClick={handlePreviewMode} disabled={!previewModeCheck}>
                Preview
              </button>
              <button className={!previewModeCheck ? "btn-preview-disable" : "btn-preview"} onClick={handlePublishClick} disabled={!previewModeCheck || isPublishing}>
                {isPublishing ? 'Publishing...' : 'Publish'}
              </button>
              <button className="btn-add-new" onClick={handleAddSection}>
                Add New
              </button>
            </Col>
          </Row>
          {loading ? (
            <div className="mt-5">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {sections.map((section, index) => (
                <Row key={section.id} className="mt-4">
                  <Col md={12} className="p-0">
                    <div className="main-section">
                      <div className="section-box">
                        <Row
                          className="d-flex align-items-center justify-content-between"
                          style={{ height: "60px" }}
                        >
                          <Col className="d-flex justify-content-between ps-4 pe-4 align-items-center">
                            <span className="section-text">
                              Section {index + 1}
                            </span>
                            <img
                              src={deleteSection}
                              alt="delete-section"
                              width={36}
                              height={36}
                              onClick={() => handleDeleteSection(section.id)}
                              style={{ cursor: "pointer" }}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div></div>
                      {isValid[section.id] &&
                        validationMessages[section.id] && (
                          <Alert
                            variant="danger"
                            className="mt-4 ms-3 me-3 text-start"
                          >
                            {validationMessages[section.id]}
                          </Alert>
                        )}
                      <div className="mt-3 d-flex flex-lg-row flex-column">
                        <Col xs={12} lg={7} md={12} className="p-4">
                          <div
                            className="d-flex flex-wrap"
                            style={{ gap: "10px" }}
                          >
                            {languages.map((language) => (
                              <button
                                key={language}
                                className={
                                  section.language === language
                                    ? "btn-english"
                                    : "btn-other"
                                }
                                id={language}
                                onClick={() =>
                                  handleLanguageChange(section.id, language)
                                }
                              >
                                {language}
                              </button>
                            ))}
                          </div>
                          <div className="mt-4">
                            <input
                              type="text"
                              ref={inputRefs[section.id]}
                              className={
                                section.language === "Arabic"
                                  ? "title-input-right"
                                  : "title-input"
                              }
                              placeholder={
                                section.language === "Arabic"
                                  ? "أضف عنوانا"
                                  : section.language === "Indonesian"
                                    ? "Tambahkan Judul"
                                    : section.language === "French"
                                      ? "Ajouter un titre"
                                      : "Add Title"
                              }
                              value={section.content[section.language]?.title}
                              onChange={(e) => {
                                let newValue = e.target.value;
                                if (newValue.length > 100) {
                                  newValue = newValue.slice(0, 100); // Truncate to 100 characters
                                }
                                handleTitleChange(section.id, section.language, newValue);
                              }}
                            />
                            <TextEditor
                              description={
                                section.content[section.language]?.description
                              }
                              setDescription={(description) =>
                                handleDescriptionChange(
                                  section.id,
                                  section.language,
                                  description
                                )
                              }
                              localization={section.language?.toLowerCase()}
                            />
                          </div>
                        </Col>
                        <Col xs={12} lg={5} md={12} className="pe-lg-4 pt-2 p-md-0 p-4">
                          <div className="sign-image-uploader mb-3 mt-3">
                            <input
                              type="file"
                              id={`fileInput-${section.id}`}
                              className="fileInput"
                              onChange={(e) => handleImageChange(section.id, e)}
                              accept="image/*"
                            />
                            <label
                              htmlFor={`fileInput-${section.id}`}
                              className="input-icon d-flex justify-content-center"
                            >
                              {section.imageSrc ? (
                                <div
                                className="backend-side-image"
                                  
                                >
                                  <img
                                    src={section.imageSrc}
                                    alt="upload"
                                    className="backend-image"
                                    style={{
                                      
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="sign-upload-label">
                                  <img
                                    src={upload}
                                    alt="upload"
                                    width={55}
                                    height={55}
                                  />
                                  <p className="sign-upload-text mt-2">
                                    Upload Image
                                  </p>
                                </div>
                              )}
                              <img
                                src={refresh}
                                alt="refresh"
                                className="refresh-icon"
                              />
                            </label>
                          </div>
                          <Col
                            md={12}
                            className="d-flex justify-content-end mt-1 pb-md-4 pt-4"
                          >
                            <button
                              className="btn-management-save mb-2"
                              onClick={() => handleSaveSection(section.id)}
                              disabled={loadingSections[section.id]}
                            >
                              {loadingSections[section.id]
                                ? "Saving..."
                                : section.id &&
                                  section.id.toString().length === 24
                                  ? "Update"
                                  : "Save"}
                            </button>
                          </Col>
                        </Col>
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Container>
      </Sidebar>
      {modalShow ? (
        <Modal
          show={modalShow}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-width"
        >
          <Modal.Body className="p-4 ">
            <div className="d-flex justify-content-center flex-column align-items-center">
              <img src={deleteIcon} alt="deleteIcon" width={150} />
            </div>
            <h4 className="modal-h4 text-center mt-4">
              Are You Sure To Delete Section?
            </h4>
            <Row>
              <Col md={6} xs={12}>
                <div
                  className="d-grid gap-2"
                  onClick={() => {
                    setModalShow(false);
                  }}
                  disabled={confirmLoading}
                >
                  <button className="modal-button-cancel mt-4" size="lg">
                    Cancel
                  </button>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="d-grid gap-2">
                  <button
                    className="modal-button-save mt-4"
                    size="lg"
                    onClick={handleDeleteSectionWithId}
                    disabled={confirmLoading}
                  >
                    {confirmLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ms-1">Loading...</span>
                      </>
                    ) : (
                      "Confirm"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
      <PreViewModal
        setPreModeModalShow={setPreModeModalShow}
        preModeModalShow={preModeModalShow}
        preModeModalLoading={preModeModalLoading}
        allSectionWithLang={allSectionWithLang}
        buttonTabs={buttonTabs}
      />
    </>
  );
};

export default ContentManagement;
