import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dataLight from "../../assets/images/data-light.svg";
import dataDark from "../../assets/images/data-dark.svg";
import profileLight from "../../assets/images/profile-light.svg";
import profileDark from "../../assets/images/profile-dark.svg";
import Navbars from "../Navbar/Navbar";
import { AuthUserContext } from "../../context/context";
import { useTranslation } from "react-i18next";
import {
  AUTH_TOKEN_KEY,
  SIM_PURCHASE,
  USER_DETAILS,
} from "../../constants/localstorageKeys";
import logo from "../../assets/images/logo.svg";
import { CiLogout } from "react-icons/ci";
import packageListing from "../../assets/images/package-listing.svg";
import packageListingActive from "../../assets/images/package-listing-active.svg";
import sims from "../../assets/images/Sims.svg";
import simsActive from "../../assets/images/sims-active.svg";
import topupListing from "../../assets/images/topup-listing.svg";
import topupListingActive from "../../assets/images/topup-listing-active.svg";
import userListing from "../../assets/images/user-listing.svg";
import userListingActive from "../../assets/images/user-listing-active.svg";
import data from "../../assets/images/Data.svg";
import dataActive from "../../assets/images/data-active.svg";
import history from "../../assets/images/History.svg";
import historyActive from "../../assets/images/history-active.svg";
import contentManagement from "../../assets/images/contentManagement.svg"
import contentManagementActive from "../../assets/images/contentManagementActive.svg"
const drawerWidth = 320;

const Sidebar = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isColor, setIsColor] = useState("");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const hasPopUp = localStorage.getItem(SIM_PURCHASE);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentLocalization, user, setUser } = useContext(AuthUserContext);
  const { pathname } = useLocation();
  const changeRoute = () => {
    try {
      if (pathname) {
        if (pathname === "/data-usage") {
          setIsColor("data_usage");
        } else if (pathname === "/my-package") {
          setIsColor("package");
        } else if (pathname === "/users-listing") {
          setIsColor("users_listing");
        } else if (pathname === "/package-listing") {
          setIsColor("package_listing");
        } else if (pathname === "/topup-listing") {
          setIsColor("topup_listing");
        } else if (pathname === "/transactions-listing") {
          setIsColor("transactions_listing");
        } else if (pathname === "/content-management") {
          setIsColor("content_management");
        }
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  useEffect(() => {
    changeRoute();
  }, [pathname]);
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
    if (userDetails) {
      setUser(userDetails);
    }
  }, []);
  const drawer = (
    <div className="stakenms-color1" style={{ position: "relative", height: "100vh" }}>
      <Toolbar className="ps-5 bg-bodys pt-3 pb-2">
        <Link to="/">
          <img src={logo} alt="logo" width={160} height={80} />
        </Link>
      </Toolbar>
      <List className="mt-4 sideber-left" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
        {user && user.role == "user" ? (
          <>
            <Link
              to="/data-usage"
              style={{ textDecoration: "none" }}
              className="text-end"
            >
              <ListItem
                key="data_usage"
                className={
                  isColor === "data_usage"
                    ? `sidebar-btn-active pt-2 pb-2 mt-3 ${currentLocalization === "ar"
                      ? "d-flex flex-row-reverse text-end"
                      : ""
                    }`
                    : `sidebar-btn pt-2 pb-2 mt-3 ${currentLocalization === "ar"
                      ? "d-flex flex-row-reverse text-end"
                      : ""
                    }`
                }
              >
                <ListItemIcon>
                  {isColor === "data_usage" ? (
                    <img
                      src={dataLight}
                      alt="dashboard_active"
                      width={20}
                      height={20}
                    />
                  ) : (
                    <img
                      src={dataDark}
                      alt="dashboard_active"
                      width={20}
                      height={20}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  className={currentLocalization === "ar" ? "me-4" : ""}
                  primary={t("dataUsage.sim")}
                />
              </ListItem>
            </Link>
            <Link
              to="/transactions-listing"
              style={{ textDecoration: "none" }}
              className="text-end"
            >
              <ListItem
                key="transactions_listing"
                className={
                  isColor === "transactions_listing"
                    ? `sidebar-btn-active pt-2 pb-2 mt-3 ${currentLocalization == "ar"
                      ? "d-flex flex-row-reverse text-end"
                      : ""
                    }`
                    : `sidebar-btn pt-2 pb-2 mt-3 ${currentLocalization == "ar"
                      ? "d-flex flex-row-reverse text-end"
                      : ""
                    }`
                }
              >
                <ListItemIcon>
                  {isColor === "transactions_listing" ? (
                    <img
                      src={historyActive}
                      alt="dashboard_active"
                      width={20}
                      height={20}
                    />
                  ) : (
                    <img
                      src={history}
                      alt="dashboard_active"
                      width={20}
                      height={20}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  className={currentLocalization == "ar" ? "me-4" : ""}
                  primary={t("dataUsage.transactionsHistory")}
                />
              </ListItem>
            </Link>
          </>
        ) : user && user.role === "admin" ? (
          <>
            <Link to="/users-listing" style={{ textDecoration: "none" }}>
              <ListItem
                key="users_listing"
                className={`${isColor === "users_listing" ? "sidebar-btn-active" : "sidebar-btn"
                  } pt-2 pb-2 mt-3 ${currentLocalization === "ar" ? "d-flex flex-row-reverse text-end" : ""
                  }`}
              >
                <ListItemIcon>
                  <img
                    src={
                      isColor === "users_listing" ? userListingActive : userListing
                    }
                    alt="dashboard_active"
                    width={23}
                    height={23}
                  />
                </ListItemIcon>
                <ListItemText
                  className={currentLocalization === "ar" ? "me-4" : ""}
                  primary={t("dataUsage.usersListing")}
                />
              </ListItem>
            </Link>
            <Link to="/package-listing" style={{ textDecoration: "none" }}>
              <ListItem
                key="package_listing"
                className={`${isColor === "package_listing" ? "sidebar-btn-active" : "sidebar-btn"
                  } pt-2 pb-2 mt-3 ${currentLocalization === "ar" ? "d-flex flex-row-reverse text-end" : ""
                  }`}
              >
                <ListItemIcon>
                  <img
                    src={
                      isColor === "package_listing" ? packageListingActive : packageListing
                    }
                    alt="dashboard_active"
                    width={20}
                    height={20}
                  />
                </ListItemIcon>
                <ListItemText
                  className={currentLocalization === "ar" ? "me-4" : ""}
                  primary={t("dataUsage.packageListing")}
                />
              </ListItem>
            </Link>
            <Link to="/my-package" style={{ textDecoration: "none" }}>
              <ListItem
                key="package"
                className={
                  isColor === "package"
                    ? `sidebar-btn-active pt-2 pb-2 mt-3 ${currentLocalization === "ar"
                      ? "d-flex flex-row-reverse text-end"
                      : ""
                    }`
                    : `sidebar-btn pt-2 pb-2 mt-3 ${currentLocalization === "ar"
                      ? "d-flex flex-row-reverse text-end"
                      : ""
                    }`
                }
              >
                <ListItemIcon>
                  {isColor === "package" ? (
                    <img
                      src={simsActive}
                      alt="dashboard_active"
                      width={23}
                      height={23}
                    />
                  ) : (
                    <img
                      src={sims}
                      alt="dashboard_active"
                      width={23}
                      height={23}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  className={currentLocalization === "ar" ? "me-4" : ""}
                  primary={t("dataUsage.sim")}
                />
              </ListItem>
            </Link>
            <Link
              to="/transactions-listing"
              style={{ textDecoration: "none" }}
              className="text-end"
            >
              <ListItem
                key="transactions_listing"
                className={
                  isColor === "transactions_listing"
                    ? `sidebar-btn-active pt-2 pb-2 mt-3 ${currentLocalization == "ar"
                      ? "d-flex flex-row-reverse text-end"
                      : ""
                    }`
                    : `sidebar-btn pt-2 pb-2 mt-3 ${currentLocalization == "ar"
                      ? "d-flex flex-row-reverse text-end"
                      : ""
                    }`
                }
              >
                <ListItemIcon>
                  {isColor === "transactions_listing" ? (
                    <img
                      src={historyActive}
                      alt="dashboard_active"
                      width={20}
                      height={20}
                    />
                  ) : (
                    <img
                      src={history}
                      alt="dashboard_active"
                      width={20}
                      height={20}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  className={currentLocalization == "ar" ? "me-4" : ""}
                  primary={t("dataUsage.transactionsHistory")}
                />
              </ListItem>
            </Link>
          </>
        ) : (
          ""
        )}


        {hasPopUp == "true" && (
          <Link to="/topup-listing" style={{ textDecoration: "none" }}>
            <ListItem
              key="topup_listing"
              className={`${isColor === "topup_listing"
                ? "sidebar-btn-active mt-3"
                : "sidebar-btn mt-3"
                } pt-2 pb-2 ${currentLocalization == "ar"
                  ? "d-flex flex-row-reverse text-end"
                  : ""
                }`}
            >
              <ListItemIcon>
                <img
                  src={
                    isColor === "topup_listing"
                      ? topupListingActive
                      : topupListing
                  }
                  alt="dashboard_active"
                  width={20}
                  height={20}
                />
              </ListItemIcon>
              <ListItemText
                className={currentLocalization == "ar" ? "me-4" : ""}
                primary={t("dataUsage.topupListing")}
              />
            </ListItem>
          </Link>
        )}
        {user && (user.role === "admin" || user.role === "member") && (
          <Link
            to="/content-management"
            style={{ textDecoration: "none" }}
            className="text-end"
          >
            <ListItem
              key="content_management"
              className={
                isColor === "content_management"
                  ? `sidebar-btn-active pt-2 pb-2 mt-3 ${currentLocalization == "ar"
                    ? "d-flex flex-row-reverse text-end"
                    : ""
                  }`
                  : `sidebar-btn pt-2 pb-2 mt-3 ${currentLocalization == "ar"
                    ? "d-flex flex-row-reverse text-end"
                    : ""
                  }`
              }
            >
              <ListItemIcon>
                {isColor === "content_management" ? (
                  <img
                    src={contentManagementActive}
                    alt="dashboard_active"
                    width={20}
                    height={20}
                  />
                ) : (
                  <img
                    src={contentManagement}
                    alt="dashboard_active"
                    width={20}
                    height={20}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                className={currentLocalization == "ar" ? "me-4" : ""}
                primary={t("dataUsage.contentManagement")}
              />
            </ListItem>
          </Link>
        )}
      </List>
      <List
        className="sideber-left"
        style={{ position: "absolute", bottom: 1 }}
      >
        {/* <ListItem
          key="users_listing"
          className={`sidebar-btn mt-2 ${
            currentLocalization == "ar"
              ? "d-flex flex-row-reverse text-end"
              : ""
          }`}
        >
          <ListItemIcon>
            <IoIosHelpCircle size={26} color="#BFC2C9" />
          </ListItemIcon>
          <ListItemText
            className={currentLocalization == "ar" ? "me-4" : ""}
            primary="Need Help?"
          />
        </ListItem> */}
        <ListItem
          key="users_listings"
          className={`sidebar-btn-login mt-2 ${currentLocalization == "ar"
            ? "d-flex flex-row-reverse text-end"
            : ""
            }`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
            localStorage.removeItem(AUTH_TOKEN_KEY);
            localStorage.removeItem(USER_DETAILS);
            localStorage.removeItem(SIM_PURCHASE);
            window.scroll(0, 0);
            setUser([]);
          }}
        >
          <ListItemIcon>
            <CiLogout size={25} color="#BFC2C9" />
          </ListItemIcon>
          <ListItemText
            className={currentLocalization == "ar" ? "me-4" : ""}
            primary={t("detailsPage.part15")}
          />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      {currentLocalization == "ar" ? (
        <Box sx={{ display: "flex" }}>
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              mr: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar
              style={{
                backgroundColor: "#FFF",
                width: "100%",
                padding: "0px",
              }}
              className="w-100"
            >
              <div className="w-100">
                <Navbars
                  handleDrawerToggle={handleDrawerToggle}
                  value="sidebar"
                />
              </div>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              mr: { sm: `${drawerWidth}px` },
            }}
            position="fixed"
            aria-label="mailbox folders"
            anchor="right"
          >
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              anchor="right"
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              anchor="right"
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            position="absolute"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
            style={{ backgroundColor: "#EFF2FB", minHeight: "100vh" }}
          >
            <Toolbar />
            {children}
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar
              style={{
                backgroundColor: "#054940",
                width: "100%",
                padding: "0",
              }}
            >
              <div className="w-100 ">
                <Navbars
                  handleDrawerToggle={handleDrawerToggle}
                  value="sidebar"
                />
              </div>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
            style={{ backgroundColor: "#F7F6FB", minHeight: "100vh" }}
          >
            <Toolbar />
            {children}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
