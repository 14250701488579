import React from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Spinner from "react-bootstrap/esm/Spinner";
const Modals = ({
  modalShow,
  setModalShow,
  handleSubmit,
  setPercentage,
  error,
  addLoading,
  percentage,
  setError
}) => {
  const handleInputChange = (event) => {
    setPercentage(event.target.value);
  };

  return (
    <div>
      {modalShow ? (
        <Modal
          show={modalShow}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-width"
        >
          <Modal.Body className="p-4">
            <h4 className="modal-h4">Add Agent Percentage</h4>
            <input
              className="modal-input mt-4"
              placeholder="Enter Percentage"
              type="number"
              min={1}
              value={percentage}
              onChange={handleInputChange}
            />
            {error && !percentage && (
              <p className="text-danger mt-2">Enter Percentage</p>
            )}
            <Row>
              <Col md={6} xs={12}>
                <div
                  className="d-grid gap-2"
                  onClick={() => {
                    setModalShow(false)
                    setError(false)
                    setPercentage('')
                  }}
                >
                  <button className="modal-button-cancel mt-4" size="lg">
                    Cancel
                  </button>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="d-grid gap-2">
                  <button
                    className="modal-button-save mt-4"
                    size="lg"
                    onClick={handleSubmit}
                    disabled={addLoading}
                  >
                    {addLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ms-1">Loading...</span>
                      </>
                    ) : (
                      "Add"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Modals;
