export const images = {
  logo: require("../assets/images/logo.png"),
  menu: require("../assets/images/menu.png"),
  offerIcon: require("../assets/images/offer-icon.png"),
  footerLogo: require("../assets/images/footer-logo.png"),
  line: require("../assets/images/Line.png"),
  mobile: require("../assets/images/mobile.png"),
  esimgo: require("../assets/images/esimgo.png"),
  esimVault: require("../assets/images/esimVault.png"),
  airalo: require("../assets/images/airalo.png"),
  loginLogo: require("../assets/images/login-logo.png"),
  loginImage: require("../assets/images/login-image.png"),
  loginEsim: require("../assets/images/login-esim.svg"),
  loginBox: require("../assets/images/login-box.png"),
  rectangle5: require("../assets/images/Rectangle5.png"),
  profileAvatar: require("../assets/images/profile-avatar.png"),
  esimAr: require("../assets/images/esim-ar.png"),
  esimFr: require("../assets/images/esim-fr.png"),
  esimIn: require("../assets/images/esim-in.png"),
  esimgoSvg : require("../assets/images/esimgo.svg"),
  airaloSvg : require("../assets/images/airalo.svg"),
  esimVaultSvg : require("../assets/images/esimVault.svg")
};
