import axios from "axios";
import routes from "../../configs/api";
import { AUTH_TOKEN_KEY } from "../../constants/localstorageKeys";
const getAllSimPackage = ({
  lang,
  page,
  perPage,
  search,
  sortBy,
  sortByOrder,
}) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  return new Promise((resolve, reject) => {
    const encodedSearch = encodeURIComponent(search);

    const URL = `${routes.GET_ALL_SIM_PACKAGE}?lang=${lang}&page=${page}&perPage=${perPage}&searchTerm=${encodedSearch}&sortByCol=${sortBy}&sortByOrder=${sortByOrder}`;
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getAllSimPackage;
