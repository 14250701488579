"use client";
import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { MdMoreHoriz } from "react-icons/md";
import { RiEditFill } from "react-icons/ri";

function DatatableMenu({onEdit,row, selectedRows}) {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState();
  const ref = useRef(null);
 
  useEffect(() => {
   
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
  const handleClose = () => {
    setShow(false);
  };
  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  return (
    <div ref={ref}>
      <MdMoreHoriz
        className="keyboardArrowDownIcon-color"
        onClick={handleClick}
        size={35}
        disabled={selectedRows.length > 0}
      />
           {
            selectedRows.length <= 0  && <Overlay
            show={show}
            target={target}
            placement="left"
            container={ref}
            containerPadding={20}
          >
            <Popover id="popover-contained">
              <Popover.Body>
                      <MenuItem
                        onClick={()=>{
                            setShow(false);
                            onEdit(row)
                        }}
                        className="menu-datatable-text"
                        disabled={selectedRows.length > 0}
                      >
                        <span><RiEditFill className="me-2" size={15} color="#215B5B"/></span> <span>Edit</span>
                      </MenuItem>
              </Popover.Body>
            </Popover>
          </Overlay>
           }
    </div>
  );
}

export default DatatableMenu;
