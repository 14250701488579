import axios from "axios";
import routes from "../../../configs/api";

const getLiveSections = async (mode, key, lang) => {
  return new Promise((resolve, reject) => {
    const URL = `${routes.GET_SECTION}?key=${key}&lang=${lang}&mode=${mode}`;
    axios
      .get(URL)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getLiveSections;
