import axios from "axios";
import routes from "../../configs/api";

const getTopupList = (id, vendorType,lang, countriesParam) => {
  return new Promise((resolve, reject) => {
    const URL = `${routes.GET_TOPUP_PACKAGE_LIST}?iccid=${id}&vendorType=${vendorType}&lang=${lang}&${countriesParam}`;
    axios
      .get(URL)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getTopupList;
