import axios from "axios";
import routes from "../../configs/api";

const checkoutSession = (data) => {
  return new Promise((resolve, reject) => {
    const URL = routes.CHECKOUT_SESSION
    axios
      .post(URL, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default checkoutSession;