import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Selects from "./Select";
const Menus = ({
  text,
  textGb,
  textWork,
  showSelect,
  setSelectedCountry,
  country,
  getCountrys,
  detailsKey,
  description,
  price,
  loading,
  selectedCountry,
  currencySymbol,
  exchangeRate,
  status,
  value,
  selectShowBaseOnRecord,
  getCountryss,
}) => {
  console.log("getCountrys", getCountrys);
  
  return (
    <div>
      <section className="home-image">
        <Container>
          <Row className="menu-padding-country-filter d-flex justify-content-center">
            <Col md={12} xs={11} className="menu-box-container">
              <Row>
                <Col md={12} xs={12} className="text-center mt-4 pb-5 pt-4">
                  {!loading && (
                    <>
                      {detailsKey === "details" ? (
                        <h3 className="menu-box-p-global">
                          {description} {text}
                          <span style={{ color: "#FDFB4D" }}>
                            &nbsp;{currencySymbol}
                            &nbsp;{(price * exchangeRate).toFixed(2)}
                          </span>
                        </h3>
                      ) : (
                        <>
                          {status == "topup" ? (
                            <h3 className="menu-box-p-global">
                              {getCountryss.length > 0 && text}
                              <span style={{ color: "#FDFB4D" }}>
                                &nbsp;{getCountrys}
                              </span>
                            </h3>
                          ) : (
                            status == "country-filter" && (
                              <h3 className="menu-box-p-global">
                                {text}
                                {getCountryss.length > 0 && 
                                <span style={{ color: "#FDFB4D" }}>
                                  &nbsp;{getCountrys}
                                </span>
                                }
                              </h3>
                            )
                          )}
                        </>
                      )}
                    </>
                  )}
                  {showSelect && (
                    <Col
                      md={6}
                      xs={10}
                      className="pt-4 menu-box-container-select bottom-3 start-50 translate-middle-x"
                    >
                      {status == "topup" && selectShowBaseOnRecord >= 1 ? (
                        <Selects
                          setSelectedCountry={setSelectedCountry}
                          country={country}
                          selectedCountry={selectedCountry}
                          status={status}
                        />
                      ) : (
                        status == "country-filter" && (
                          <Selects
                            setSelectedCountry={setSelectedCountry}
                            country={country}
                            selectedCountry={selectedCountry}
                            status={status}
                          />
                        )
                      )}
                    </Col>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Menus;
