import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar/SideBar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../context/context";
import Form from "react-bootstrap/Form";
import Input from "../../component/Input";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import MultiSelect from "../../component/MultiSelect ";
import { currencySymbols } from "../../constants/currency";
import PasswordInput from "../../component/PasswordInput";
import { useNavigate } from "react-router-dom";
import { USER_DETAILS } from "../../constants/localstorageKeys";
import { LuCopy, LuCopyCheck } from "react-icons/lu";

const ProfileSetting = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [countryStage, setCountryStages] = useState([]);
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const [referralLink, setReferralLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleTogglePassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      //   handleSubmit(event);
    }
  };
  const handleCancel = (event) => {
    event.preventDefault();
    navigate("/data-usage");
    window.scroll(0, 0);
  };
  const handleCopy = () => {
    if (referralLink) {
      navigator.clipboard
        .writeText(referralLink)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 1000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } 
  };
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
    if (userDetails) {
      setUser(userDetails);
    }
  }, []);
  useEffect(() => {
    if (user?.referralCode) {
      const baseUrl = window.location.origin;
      setReferralLink(`${baseUrl}?ref=${user?.referralCode}`);
    }
  }, [user?.referralCode]);
  return (
    <Sidebar>
      <Container>
        <Row>
          <Col>
            <div
              className={
                currentLocalization == "ar" ? "text-end" : "text-start"
              }
            >
              <h3 className="package-h2 mt-5">
                {t("dataUsage.profileSettings")}
              </h3>
            </div>
          </Col>
        </Row>

        {/* <Form
          //   onSubmit={handleSubmit}
          // method="post"
          onKeyDown={handleKeyDown}
          className=""
        > */}
        <Row
          className={` ${
            currentLocalization == "ar"
              ? "d-flex flex-row-reverse text-end mt-4"
              : "d-flex flex-row text-start mt-4"
          }`}
        >
          <Col xs={12} md={5}>
            <Form.Group as={Col} md="12" className="d-flex flex-column mt-3">
              <Input
                labelClassName="label"
                labelText={t("signUp.part3")}
                type="text"
                name="name"
                value={user?.name}
                readOnly={true}
                //   onChange={handleChange}
                placeholder={t("signUp.part4")}
                className={
                  currentLocalization == "ar"
                    ? "login-input-right"
                    : "login-input-left"
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={5} className="ms-md-3">
            <Form.Group as={Col} md="12" className="d-flex flex-column mt-3">
              <Input
                labelClassName="label"
                labelText={t("login.part3")}
                type="email"
                name="email"
                value={user?.email}
                readOnly={true}
                //   onChange={handleChange}
                placeholder={t("login.part4")}
                className={
                  currentLocalization == "ar"
                    ? "login-input-right"
                    : "login-input-left"
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={5}>
            <Form.Group as={Col} md="12" className="d-flex flex-column mt-3">
              <Input
                labelClassName="label"
                labelText={t("signUp.part5")}
                type="text"
                name="phoneNumber"
                value={`+${user?.phoneNo}`}
                readOnly={true}
                //   onChange={handleChange}
                placeholder={t("signUp.part6")}
                className={
                  currentLocalization == "ar"
                    ? "login-input-right"
                    : "login-input-left"
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={5} className="ms-md-3 mt-3">
            <label className="label">
              {t("detailsPage.part13")}<span className="text-danger-asterisk"> *</span>
            </label>
            <div className={`selected-options-signup  ${
                    currentLocalization == "ar"
                      ? "d-flex justify-content-end"
                      : " d-flex justify-content-start"
                  }`}>
              {user?.countries.map((country) => (
                <span
                  key={country}
                  className={`selected-option-signup-profile ${
                    currentLocalization == "ar"
                      ? "flex-row d-flex align-items-center"
                      : " d-flex flex-row align-items-center"
                  }`}
                >
                  <span>{t(`countryName.${country}`)}</span>
                </span>
              ))}
            </div>

            {/* <Form.Group as={Col} md="12" className="mt-3"> */}
            {/* <MultiSelect
                  onChange={handleChange}
                  name="investmentStages"
                  setFormInfo={setFormData}
                  selectedOptions={countryStage}
                  setSelectedOptions={setCountryStages}
                  formName="investmentStages"
                  type="multiple"
                  options={currencySymbols}
                  label={t("signUp.part13")}
                  labelClassName="label"
                  labelText={t("signUp.part7")}
                  currentLocalization={currentLocalization}
                /> */}
            {/* </Form.Group> */}
          </Col>
          <Col xs={12} md={5}>
            <Form.Group as={Col} md="12" className="d-flex flex-column mt-3">
              <label className="label">{t("signUp.part16")}</label>
              <div
                className={
                  currentLocalization == "ar"
                    ? "login-input-right-referral"
                    : "login-input-left "
                }
              >
                <input
                  type="text"
                  name="referralLink"
                  value={referralLink}
                  readOnly={true}
                  placeholder={t("signUp.part16")}
                  className={
                    currentLocalization == "ar" ? "offdK-right" : "offdK"
                  }
                />
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{t("link copied")}</Tooltip>}
                  show={isCopied}
                >
                  <button className="copy-button" onClick={handleCopy} disabled={!referralLink}>
                    {isCopied ? (
                      <LuCopyCheck size={30} />
                    ) : (
                      <LuCopy size={30} />
                    )}
                  </button>
                </OverlayTrigger>
              </div>
            </Form.Group>
          </Col>
          {/* <Col xs={12} md={5}>
              <Form.Group as={Col} md="12" className="mt-1">
                <PasswordInput
                  handleTogglePassword={handleTogglePassword}
                  label={t("login.part5")}
                  showPassword={showPassword}
                  name="password"
                  className={
                    currentLocalization == "ar"
                      ? "login-input-password-right"
                      : "login-input-password-left"
                  }
                  labelClassName="label"
                  placeholder={t("login.part6")}
                  currentLocalization={currentLocalization}
                  value={formData.password}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col> */}
          {/* <Col
              className="mt-5 ms-2"
              md={10}
              xs={11}
              style={{ border: "1px solid #D9D9D9" }}
            ></Col>
            <Col md={10} xs={11} className="mt-5 ms-2 p-0">
              <Row className={currentLocalization == "ar" ? "d-flex justify-content-start" : "d-flex justify-content-end"}>
                <Col md={3} xs={11}>
                  <div className="d-grid gap-2">
                    <button className="profile-cancel" onClick={handleCancel}>{t("dataUsage.Cancel")}</button>
                  </div>
                </Col>
                <Col md={3} xs={11}>
                  <div className="d-grid gap-2" >
                    <button className="profile-save">{t("newPassword.part5")}</button>
                  </div>
                </Col>
              </Row>
            </Col> */}
        </Row>
        {/* </Form> */}
      </Container>
    </Sidebar>
  );
};

export default ProfileSetting;
