import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Footer from "../../component/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { AuthUserContext } from "../../context/context";
import Navbars from "../../component/Navbar/Navbar";
import { CURRENCY, USER_DETAILS } from "../../constants/localstorageKeys";
import SectionMode from "../../component/SectionMode";
import getLiveSections from "../../services/api/Sections/getLiveSections";
import ComingSoonSvg from "../../assets/images/comingsoon.svg";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const { currentLocalization, setLinkCopy, setTopUpLink } =
    useContext(AuthUserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLinkCopy("");
        setTopUpLink("");
        setLoading(true);
        const response = await getLiveSections(
          "liveMode",
          // "previewMode",
          "About Us",
          currentLocalization
        );
        setSections(response.data);
      } catch (error) {
        console.error("Error fetching live sections:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentLocalization]);

  return (
    <div className="offers-image">
      <Navbars />
      <Container>
        {loading ? (
          <div className="w-100 pt-5 pb-5">
            <Spinner animation="border" variant="dark" />
          </div>
        ) : (
          <div className="mt-5 mb-5">
            {sections.length > 0 ? (
              <SectionMode allSectionWithLang={sections} />

            ) : (
              <img
                src={ComingSoonSvg}
                alt="offer-icon"
                width={1000}
                height={600}
                style={{ borderRadius: "50%" }}
              />
            )}
          </div>
        )}
        <div className="mt-5">
          <Footer />
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
