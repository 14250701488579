import React, { useState } from "react";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
const ButtonGroups = ({ setButtonTabs, buttonTabs, setSections }) => {
  const handleButtonClick = (filter) => {
    setButtonTabs(filter);
    setSections([
      {
        id: Date.now(),
        language: "English",
        content: {
          English: { title: "", description: "" },
          Arabic: { title: "", description: "" },
          Indonesian: { title: "", description: "" },
          French: { title: "", description: "" },
        },
        imageSrc: null,
        imageUrl: null,
      },
    ]);
  };
  return (
    <Row>
      <Col xl={6} lg={8} md={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <ButtonGroup
            size="large"
            className="button-groups"
            aria-label="large button group"
          >
            <button
              key="one"
              className={
                buttonTabs === "Home" ? "client-btn" : "btn btn-transparent-all"
              }
              onClick={() => handleButtonClick("Home")}
              disabled={buttonTabs === "Home"}
            >
              Home
            </button>
            <button
              key="two"
              className={
                buttonTabs === "About Us"
                  ? "client-btn-received"
                  : "btn btn-transparent-received"
              }
              onClick={() => handleButtonClick("About Us")}
              disabled={buttonTabs === "About Us"}
            >
              About Us
            </button>
            <button
              key="three"
              className={
                buttonTabs === "Contact Us"
                  ? "client-btn-contact"
                  : "btn btn-transparent-contact"
              }
              onClick={() => handleButtonClick("Contact Us")}
              disabled={buttonTabs === "Contact Us"}
            >
              Contact Us
            </button>
          </ButtonGroup>
        </Box>
      </Col>
    </Row>
  );
};

export default ButtonGroups;
