import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Spinner from "react-bootstrap/esm/Spinner";
import { images } from "../utils/image";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";
import { useNavigate } from "react-router-dom";
const TopUpModal = ({
  selected,
  handleSelect,
  setModalShow,
  modalShow,
  simRecord,
  onClick,
  selectLoading,
  error,
  selectValueShow,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);
  return (
    <div>
      {modalShow ? (
        <Modal
          show={modalShow}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-width"
          // style={{ background: "rgb(153 152 152 / 97%)" }}
        >
          <Modal.Body>
            <div className="m-2">
              <h4 className="modal-h4 mb-4">{t("detailsPage.part11")}</h4>
              <div className="scroll-set mt-3">
                {simRecord.map((items, index) => (
                  <div
                    key={index}
                    className={`checkbox-container ${
                      selected === items ? "selected" : ""
                    }`}
                    onClick={() => handleSelect(items)}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center ">
                        <div className="" style={{ width: "55px" }}>
                          <img
                            src={
                              items?.vendorType == "esimgo" ? images.esimgo : items?.vendorType === "esimVault" ? images.esimVault : images.airalo
                            }
                            alt="offer-icon"
                            width={items?.vendorType == "esimgo" ? 35 : 50}
                          />
                        </div>
                        <span className=" ms-2">
                          {items.sims[0]?.iccid}
                        </span>
                      </div>
                      <div className="me-3">
                        {selectValueShow?.length > 0 &&
                          items?._id === selectValueShow[0]._id && (
                            <span className="select-value">selected</span>
                          )}
                      </div>
                    </div>
                    <span
                      className={`checkboxss ${
                        selected === items ? "checked" : ""
                      }`}
                    />
                  </div>
                ))}
              </div>
              <div
                className={
                  currentLocalization == "ar"
                    ? "text-end mt-2"
                    : "text-start mt-2"
                }
              >
                {error && (
                  <span className="text-danger">
                    {t("validation.simNumberSelect")}
                  </span>
                )}
              </div>
              <Row>
                <Col md={6} xs={12}>
                  <div
                    className="d-grid gap-2"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <button className="modal-button-cancel mt-4" size="lg">
                      {t("dataUsage.Cancel")}
                    </button>
                  </div>
                </Col>
                <Col md={6} xs={12}>
                  <div className="d-grid gap-2">
                    <button
                      className={selectLoading || selected == null ? "modal-button-save-disable mt-4" : "modal-button-save mt-4"}
                      size="lg"
                      onClick={onClick}
                      disabled={selectLoading || selected == null}
                    >
                      {selectLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="ms-1">{t("login.part16")}...</span>
                        </>
                      ) : (
                        t("detailsPage.part14")
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TopUpModal;
