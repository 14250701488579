import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RegisterSidebar from "../../component/RegisterSidebar";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import Input from "../../component/Input";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import PasswordInput from "../../component/PasswordInput";
import { AuthUserContext } from "../../context/context";
import { Link, useNavigate } from "react-router-dom";
import MultiSelect from "../../component/MultiSelect ";
import { currencySymbols } from "../../constants/currency";
import { toast } from "react-toastify";
import register from "../../services/auth/register";
import Spinner from "react-bootstrap/Spinner";
import { AUTH_TOKEN_KEY, USER_DETAILS } from "../../constants/localstorageKeys";
import authLogo from "../../assets/images/auth-logo.svg";
import LanguageSelect from "../../component/LanguageSelect";
import { useLocation } from "react-router-dom";
import PhoneInput from "../../component/PhoneInput";

const SignUp = ({setEventTriggered}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [countryStage, setCountryStages] = useState([]);
  const { currentLocalization, setToastId, toastId } = useContext(AuthUserContext);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referralLink, setReferralLink] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    referralCode: referralCode ?? "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPhoneNumberError(false);
    //   if (name === "phoneNumber" && value.length > 17) {
    //     setPhoneNumberError(true)
    //     return;
    // }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setPhoneNumberError(false);
      const { name, email, password } = formData;
      if (
        !name ||
        !email ||
        !phoneNumber ||
        !password ||
        !countryStage.length
      ) {
        setValidated(true);
        return;
      }
      let lang;
      if (currentLocalization.startsWith("en-")) {
        lang = "en";
      } else {
        lang = currentLocalization;
      }
      setValidated(false);
      if (password.length < 6) {
        setPasswordValidation(true);
        return;
      }
      setLoading(true);
      setPasswordValidation(false);
      const object = {
        name: name,
        email: email,
        phone_no: phoneNumber,
        password: password,
        countries: countryStage,
        referralCode: referralCode,
        lang: lang,
      };
      const response = await register(object);
      if (response) {
        const userDetails = {
          id: response?.data?.id,
          email: response?.data?.email,
          name: response?.data?.name,
          role: response?.data?.role,
          phoneNo: response?.data?.phone_no,
          otpVerified: response?.data?.otpVerified,
          countries: response?.data?.countries,
          referralCode: response?.data?.referralCode,
        };
        const id = toast.success(response?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        navigate("/otp-verification", {
          state: { email: response?.data?.email },
        });
        window.scroll(0, 0);
      }
    } catch (err) {
      if (err.response?.status == 409) {
        const id = toast.error(err.response?.data?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      } else if (err.response?.status == 400) {
        const id = toast.error(err.response?.data?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      } else {
        const id = toast.error(err?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !loading) {
      if (toastId) setEventTriggered(true);
      event.preventDefault();
      handleSubmit(event);
    }
  };
  const handleTogglePassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    const refCode = new URLSearchParams(location.search).get("ref");
    const baseUrl = window.location.origin;
    setReferralLink(`${baseUrl}?ref=${refCode}`);
    setReferralCode(refCode);
    window.scroll(0,0)
  }, []);
  return (
    <Container fluid className="main-login-image">
      <Row className="row-login d-flex justify-content-center">
        <Col
          xs={11}
          md={6}
          className="loginImage p-0 d-flex justify-content-center align-items-center"
        >
          <RegisterSidebar />
        </Col>
        <Col xs={12} md={6} className=" text-start login-side">
          <Row className="d-flex justify-content-center align-items-center pt-5">
            <Col
              md={9}
              className={`pt-0 p-md-0 p-4 d-flex align-items-center justify-content-between ${
                currentLocalization == "ar" && "flex-row-reverse"
              }`}
            >
              <Link to="/">
                <img src={authLogo} alt="" />
              </Link>
              <LanguageSelect />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center pt-4 pb-5 h-75">
            <Col md={9} className="pt-0 p-md-0 p-4">
              <div
                style={{ marginTop: "35px" }}
                className={`p-0 ${
                  currentLocalization == "ar" ? "text-end" : "text-start"
                }`}
              >
                <h2 className="login-welcome">{t("signUp.part1")}</h2>
                <Form
                  onSubmit={handleSubmit}
                  method="post"
                  onKeyDown={handleKeyDown}
                  className="mt-4"
                >
                  <Form.Group as={Col} md="12" className="d-flex flex-column">
                    <Input
                      labelClassName="label"
                      labelText={t("signUp.part3")}
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder={t("signUp.part4")}
                      className={
                        currentLocalization == "ar"
                          ? "login-input-right"
                          : "login-input-left"
                      }
                    />
                    <div
                      className={
                        currentLocalization == "ar"
                          ? "text-end mt-2"
                          : "text-start mt-2"
                      }
                    >
                      {validated && !formData.name && (
                        <span className="text-danger">
                          {t("validation.nameRequired")}
                        </span>
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    className="d-flex flex-column mt-1"
                  >
                    <Input
                      labelClassName="label"
                      labelText={t("login.part3")}
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder={t("login.part4")}
                      className={
                        currentLocalization == "ar"
                          ? "login-input-right"
                          : "login-input-left"
                      }
                    />
                    <div
                      className={
                        currentLocalization == "ar"
                          ? "text-end mt-2"
                          : "text-start mt-2"
                      }
                    >
                      {validated && !formData.email && (
                        <span className="text-danger">
                          {t("validation.emailRequired")}
                        </span>
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group as={Col} md="12" className="mt-1">
                    <MultiSelect
                      onChange={handleChange}
                      name="investmentStages"
                      setFormInfo={setFormData}
                      selectedOptions={countryStage}
                      setSelectedOptions={setCountryStages}
                      formName="investmentStages"
                      type="multiple"
                      options={currencySymbols}
                      label={t("signUp.part13")}
                      labelClassName="label"
                      labelText={t("signUp.part7")}
                      currentLocalization={currentLocalization}
                    />
                    <div
                      className={
                        currentLocalization == "ar"
                          ? "text-end mt-2"
                          : "text-start mt-2"
                      }
                    >
                      {validated && !countryStage.length && (
                        <span className="text-danger">
                          {t("validation.atLeastOneCountryRequired")}
                        </span>
                      )}
                    </div>
                  </Form.Group>
                  <PhoneInput
                    className={
                      currentLocalization == "ar"
                        ? "login-input-right"
                        : "login-input-left"
                    }
                    labelClassName="label"
                    labelText={t("signUp.part5")}
                    setPhoneNumber={setPhoneNumber}
                    phoneNumber={phoneNumber}
                  />
                  <div
                    className={
                      currentLocalization == "ar"
                        ? "text-end mt-2"
                        : "text-start mt-2"
                    }
                  >
                    {validated && !phoneNumber && (
                      <span className="text-danger">
                        {t("validation.phoneRequired")}
                      </span>
                    )}
                  </div>
                  <Form.Group as={Col} md="12" className="mt-1">
                    <PasswordInput
                      handleTogglePassword={handleTogglePassword}
                      label={t("login.part5")}
                      showPassword={showPassword}
                      name="password"
                      className={
                        currentLocalization == "ar"
                          ? "login-input-password-right"
                          : "login-input-password-left"
                      }
                      labelClassName="label"
                      placeholder={t("login.part6")}
                      currentLocalization={currentLocalization}
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <div
                      className={
                        currentLocalization == "ar"
                          ? "text-end mt-2"
                          : "text-start mt-2"
                      }
                    >
                      {validated && !formData.password ? (
                        <span className="text-danger">
                          {t("validation.passwordRequired")}
                        </span>
                      ) : (
                        passwordValidation && (
                          <span className="text-danger">
                            {t("validation.passwordLength")}
                          </span>
                        )
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    className="d-flex flex-column mt-1"
                  >
                    <Input
                      labelClassName="label"
                      labelText={t("signUp.part16")}
                      type="text"
                      name="link"
                      value={referralCode ? referralLink : ""}
                      placeholder={t("login.part17")}
                      className={
                        currentLocalization == "ar"
                          ? "login-input-right-referralCode"
                          : "login-input-left-referralCode"
                      }
                      readOnly="readOnly"
                      asterisk="referralLink"
                    />
                  </Form.Group>
                  <Row style={{ marginTop: "30px" }}>
                    <Col>
                      <div className="d-grid gap-2 ">
                        <button
                          className={
                            loading
                              ? "btn-login-main-disabled"
                              : "btn-login-main"
                          }
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              {currentLocalization == "ar" ? (
                                <>
                                  <span className="me-2">
                                    ... {t("login.part16")}
                                  </span>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                              ) : (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <span className="ms-2">
                                    {t("login.part16")} ...
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {currentLocalization == "ar" ? (
                                <>
                                  <GrFormPreviousLink
                                    size={27}
                                    className="me-1"
                                  />
                                  {t("signUp.part10")}
                                </>
                              ) : (
                                <>
                                  {t("signUp.part10")}
                                  <GrFormNextLink size={27} className="ms-1" />
                                </>
                              )}
                            </>
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <div className="text-center" style={{ marginTop: "25px" }}>
                  <span className="dont-login">{t("signUp.part11")}</span>
                  <Link
                    to="/log-in"
                    style={{ textDecoration: "none" }}
                    onClick={() => window.scroll(0, 0)}
                  >
                    <span className="dont-login-today">
                      &nbsp;{t("signUp.part12")}
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
