import axios from "axios";
import routes from "../../../configs/api";

const getBundleDetails = (name, key, lang) => {
  return new Promise((resolve, reject) => {
    const URL = `${routes.BUNDLE_DETAILS}?name=${name}&key=${key}&lang=${lang}`;
    axios
      .get(URL)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getBundleDetails;