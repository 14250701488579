import axios from "axios";
import routes from "../../configs/api";

const resendOTP = (data) => {
  return new Promise((resolve, reject) => {
    const URL = routes.RESEND_OTP;
    axios
      .post(URL, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default resendOTP;
