import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { TbWorld } from "react-icons/tb";
import Footer from "../../component/Footer/Footer";
import Menus from "../../component/Menu";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import getBundleDetails from "../../services/api/eSim/getBundleDetails";
import { AuthUserContext } from "../../context/context";
import Spinner from "react-bootstrap/esm/Spinner";
import checkoutSession from "../../services/stripe/checkoutSession";
import { useLocation } from "react-router-dom";
import Navbars from "../../component/Navbar/Navbar";
import esimVaultSvg from "../../assets/images/esimVault.svg";

import {
  CURRENCY,
  discount,
  USER_DETAILS,
} from "../../constants/localstorageKeys";
import currencyExchange from "../../services/api/currencyyExchange";
import RegisterOffers from "../../component/RegisterOffers";
import {
  countryCode,
  countryCurrency,
  currencySymbols,
} from "../../constants/currency";
import CurrencyNameSelect from "../../component/CurrencyNameSelect";
const OfferDetail = () => {
  const { t } = useTranslation();
  const [discountPer, setDiscountPer] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [bundleDetail, setBundleDetails] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const param_id = searchParams.get("id");
  const param_key = searchParams.get("key");
  const { currentLocalization, setLinkCopy, user, countryCodes, setToastId } =
    useContext(AuthUserContext);
  const newuser = JSON.parse(localStorage.getItem(USER_DETAILS));
  const getCurrency = localStorage.getItem(CURRENCY);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const handleCurrencyChange = ({ exchangeRate, currency }) => {
    setExchangeRate(exchangeRate);
    setCurrencySymbol(currency);
  };
  const bundleDetails = async () => {
    try {
      const response = await currencyExchange();
      if (param_id && param_key) {
        const response = await getBundleDetails(
          param_id,
          param_key,
          currentLocalization
        );
        if (response.success) {
          setBundleDetails(response?.data);
        }
      } else {
        navigate("/");
      }
    } catch (err) {
      const id = toast.error(err.response?.data?.message || err.message, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true
      });
      setToastId(id);
    } finally {
      setLoading(false);
    }
  };
  const handleBuyNowClicked = async () => {
    try {
      setButtonLoading(true);
      if (newuser) {
        let response = await checkoutSession({
          ...bundleDetail,
          userId: user.id,
          lang: currentLocalization,
          currency: getCurrency,
        });
        if (response) {
          window.location.href = response.url;
        }
      } else {
        setLinkCopy(location?.search);
        navigate("/log-in");
        const id = toast.error(t("toastsMessage.part3"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        window.scroll(0, 0);
      }
    } catch (err) {
      setButtonLoading(false);
      console.log("err", err);
      const id = toast.error(err.response?.data?.message || err.message, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true
      });
      setToastId(id);
    }
  };
  const currencyCHange = async () => {
    try {
      if (getCurrency) {
        const newCode = countryCodes ? countryCodes : getCurrency;
        const getCountryCurrency = countryCurrency.find(
          (code) => code.currency_code === newCode
        );
        const country = countryCode.find(
          (country) => country.countryName === getCountryCurrency.country_code
        );
        const newCurrency = country ? country.countryCurrency : "USD";
        const currencyCode =
          currencySymbols.find((symbol) => symbol.Code === newCurrency)
            ?.Symbol ?? newCode;
        setCurrencySymbol(currencyCode);
        setSelectedCurrency(newCurrency);
        const response = await currencyExchange();
        const exchangeRate = response.rates[newCurrency];
        setExchangeRate(exchangeRate);
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  const renderCountries = (countries) => {
    if (!countries) return "";

    let moreDestinationsPattern;
    switch (currentLocalization) {
      case "en":
        moreDestinationsPattern = /\d+ more destinations/;
        break;
      case "fr":
        moreDestinationsPattern = /\d+ plus de destinations/;
        break;
      case "in":
        moreDestinationsPattern = /\d+ lebih banyak tujuan/;
        break;
      case "ar":
        moreDestinationsPattern = /\d+ المزيد من الوجهات/;
        break;
      default:
        moreDestinationsPattern = /\d+ more destinations/;
    }

    const match = countries.match(moreDestinationsPattern);
    if (match) {
      const moreDestinationsText = match[0];
      const cleanedCountries = countries.replace(moreDestinationsPattern, "");
      return (
        <span>
          {cleanedCountries.trim()}
          &nbsp;
          <a href="#more-countries" className="offers-works-span-a">
            {moreDestinationsText}
          </a>
        </span>
      );
    }

    return <span>{countries}</span>;
  };
  useEffect(() => {
    currencyCHange();
  }, [getCurrency]);
  useEffect(() => {
    bundleDetails();
    // setSelectedCurrency(getCurrency)
    setDiscountPer(discount);
  }, []);
  return (
    <>
      <Navbars />
      <Container>
        {newuser ? null : (
          <Row className="pt-4">
            <Col className="text-start p-0">
              <RegisterOffers />{" "}
            </Col>
          </Row>
        )}
        <Row className="d-flex justify-content-center p-0">
          <Col md={12} className="p-0 d-flex justify-content-end mt-4">
            <Col md={1} xs={12} className="">
              <CurrencyNameSelect
                onCurrencyChange={handleCurrencyChange}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                value="home"
              />
            </Col>
          </Col>
          {loading ? (
            <div
              className="w-100"
              style={{ paddingTop: "80px", paddingBottom: "80px" }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <Col
              md={12}
              xs={12}
              className="offer-card-box-green-offer mb-5 p-0 mt-4"
            >
              <Col
                md={12}
                xs={12}
                className="offer-card-box-top-green-details p-2"
              >
                <Row
                  className={`d-flex align-items-center justify-content-between mt-2 mb-2 ${currentLocalization == "ar"
                    ? "flex-row-reverse"
                    : "flex-row"
                    }`}
                >
                  <Col
                    lg={5}
                    md={4}
                    xs={12}
                    className={`d-flex align-items-center ms-2 ${currentLocalization == "ar"
                      ? "flex-row-reverse"
                      : "flex-row"
                      }`}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={bundleDetail?.key === "esimVault" ? esimVaultSvg : bundleDetail?.imageUrl}
                        alt="offer-icon"
                        width={55}
                        height={55}
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                    <Row
                      className={`${currentLocalization == "ar" ? "me-1" : "ms-1"
                        }`}
                    >
                      <Col
                        md={12}
                        xs={11}
                        className={`${currentLocalization == "ar"
                          ? "d-flex flex-row-reverse "
                          : "text-start d-flex justify-content-start"
                          }`}
                      >
                        <h3 className="global-h3-details mt-2 ps-1">
                          {bundleDetail?.description}
                        </h3>
                      </Col>
                      <Col md={12} xs={11}>
                        <div
                          className={`offers-works-span text-start d-flex  ${currentLocalization == "ar"
                            ? "d-flex flex-row-reverse"
                            : ""
                            }`}
                        >
                          <span>
                            <TbWorld size={17} />
                          </span>
                          <span
                            style={{ marginTop: "2px" }}
                            className={
                              currentLocalization == "ar" ? "pe-1" : "ps-1"
                            }
                          >
                            <b>
                              {renderCountries(
                                `${t("countryFilter.part31")} ${t(
                                  `countryName.${bundleDetail?.country}`
                                )}${bundleDetail?.countries_count
                                  ? ` & ${bundleDetail?.countries_count - 1
                                  } ${t("countryFilter.part32")}`
                                  : ""
                                }`
                              )}
                            </b>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={5} md={4} xs={12}>
                    <Row
                      className={`d-flex  ${currentLocalization === "ar"
                        ? "justify-content-start"
                        : "justify-content-end"
                        }`}
                    >
                      <Col
                        lg={3}
                        md={3}
                        xs={4}
                        className={`d-flex  flex-column p-0 mt-3 mb-2 ${currentLocalization === "ar"
                          ? "pe-4 text-end"
                          : "text-start ps-4"
                          }`}
                        style={{ borderRight: "1px solid #DEDEDE" }}
                      >
                        <span className="offer-validity-span ">
                          {t("home.part18")}
                        </span>
                        {currentLocalization === "ar" ? (
                          <div className="d-flex align-items-center justify-content-end">
                            <span className="offer-days-span ">
                              {t("home.part21")}
                            </span>
                            <span className="offer-days-span"> &nbsp;{bundleDetail?.duration}</span>
                          </div>
                        ) : (
                          <span className="offer-days-span ps-1">
                            {bundleDetail?.duration} {t("home.part21")}
                          </span>
                        )}
                      </Col>
                      <Col
                        md={3}
                        xs={3}
                        className={`d-flex flex-column  mt-3 mb-2 ${currentLocalization === "ar"
                          ? "pe-4 text-end"
                          : "text-start offer-details-padding"
                          }`}
                        style={{ borderRight: "1px solid #DEDEDE" }}
                      >
                        <span className="offer-validity-span p-0">
                          {t("home.part19")}
                        </span>
                        {currentLocalization === "ar" ? (
                          <div className="d-flex align-items-center justify-content-end">
                            <span className="offer-days-span">
                              {t("home.part22")}
                            </span>
                            <span className="offer-days-span"> &nbsp;{bundleDetail?.dataAmount}</span>
                          </div>
                        ) : (
                          <span className="offer-days-span ">
                            {bundleDetail?.dataAmount} {t("home.part22")}
                          </span>
                        )}
                      </Col>
                      <Col
                        md={3}
                        xs={3}
                        className="d-flex flex-column text-start ps-3 p-0 mt-3 mb-2"
                      >
                        <span
                          className={`offer-validity-span ${currentLocalization === "ar" ? "ps-3" : "p-0"
                            }`}
                        >
                          {t("home.part20")}
                        </span>
                        <span
                          className={`offer-days-span ${user && user.parent_id ? "" : "price-offer"
                            }`}
                        >
                          {currencySymbol == "Rp" ? (
                            <>
                              {currencySymbol}&nbsp;
                              {(bundleDetail?.price * exchangeRate).toFixed(0)}
                            </>
                          ) : (
                            <>
                              {currencySymbol}&nbsp;
                              {(bundleDetail?.price * exchangeRate).toFixed(2)}
                            </>
                          )}
                        </span>
                      </Col>
                    </Row>
                    {
                      user && user.parent_id ? null : <Row
                        className={`d-flex pt-3 ${currentLocalization === "ar"
                          ? "justify-content-start"
                          : "justify-content-end"
                          }`}
                        style={{ minHeight: "30px" }}
                      >
                        <Col
                          lg={6}
                          md={4}
                          xs={12}
                          className={`${currentLocalization === "ar"
                            ? "text-center"
                            : "text-start discount-label-text"
                            }`}
                        >
                          <span className="offer-validity-span-dicount">
                            {t("home.part32")}
                          </span>
                        </Col>
                        <Col md={3} xs={3} className="text-start ">
                          <span className="offer-days-span-discount-price ms-1 ">
                            {currencySymbol == "Rp" ? (
                              <>
                                {currencySymbol}&nbsp;
                                {(
                                  bundleDetail?.discountedPrice * exchangeRate
                                ).toFixed(0)}
                              </>
                            ) : (
                              <>
                                {currencySymbol}&nbsp;
                                {(
                                  bundleDetail?.discountedPrice * exchangeRate
                                ).toFixed(2)}
                              </>
                            )}

                          </span>
                        </Col>
                      </Row>
                    }

                  </Col>
                </Row>

                <Row>
                  <Col
                    md={12}
                    xs={12}
                    className={`text-start mt-3 ps-4 pe-4 d-flex flex-wrap ${currentLocalization === "ar" && "flex-row-reverse"
                      }`}
                  >
                    <p className="home-time-span text-start ">
                      {bundleDetail?.description}
                    </p>
                  </Col>
                </Row>
                <Row
                  className={`d-flex justify-content-end p-3 ${currentLocalization === "ar" && "flex-row-reverse"
                    }`}
                >
                  <Col
                    md={currentLocalization == "ar" ? 4 : 4}
                    xs={12}
                    className={
                      currentLocalization == "ar"
                        ? "ms-md-5 p-0"
                        : "me-md-4 p-0"
                    }
                  >
                    <div
                      className={`d-grid gap-2 d-flex  ${currentLocalization == "ar"
                        ? "justify-content-start me-3"
                        : "justify-content-end pe-4"
                        }`}
                    >
                      <button
                        className={
                          buttonLoading || user?.role == "admin"
                            ? "view-offer-button-buy-disabled"
                            : `${currentLocalization == "ar"
                              ? "view-offer-button-buy-ar"
                              : "view-offer-button-buy"
                            }`
                        }
                        size="lg"
                        onClick={handleBuyNowClicked}
                        disabled={buttonLoading || user?.role == "admin"}
                      >
                        {buttonLoading ? (
                          <>
                            {currentLocalization == "ar" ? (
                              <>
                                <span className="me-2">
                                  ... {t("login.part16")}
                                </span>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </>
                            ) : (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                <span className="ms-2">
                                  {t("login.part16")} ...
                                </span>
                              </>
                            )}
                          </>
                        ) : (
                          <>{t("home.part27")}</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Col>
          )}
          <Col
            md={12}
            xs={11}
            className="offer-details-box text-start p-4 mt-3 mb-5 "
          >
            <div
              className={
                currentLocalization == "ar" ? " text-end" : "text-start"
              }
            >
              <p className="offer-instant-p">{t("detailsPage.part1")}</p>
              <ul
                className="offer-list ms-md-4 "
                style={{ direction: currentLocalization == "ar" && "rtl" }}
              >
                <li>⚠️ {t("detailsPage.part2")}</li>
                <li>{t("detailsPage.part3")}</li>
                <li>{t("detailsPage.part4")}</li>
                <li>{t("detailsPage.part5")}</li>
                <li>{t("detailsPage.part6")}</li>
                <li>{t("detailsPage.part7")}</li>
                <li>{t("detailsPage.part8")}</li>
                <li>{t("detailsPage.part6")}</li>
              </ul>
            </div>
          </Col>
          <Col
            md={12}
            xs={11}
            className="offer-details-box text-start p-4 mt-3 mb-5"
            id="more-countries"
          >
            <p
              className={`offer-instant-p ${currentLocalization == "ar" ? " text-end" : "text-start"
                }`}
            >
              {t("detailsPage.part10")}
            </p>
            <Row className="">
              {bundleDetail &&
                bundleDetail.countriesArray &&
                bundleDetail.countriesArray.map((country) => {
                  // Split the country name at the first '(' and take the part before it
                  const countryName = country.name.split(" (")[0];

                  return (
                    <Col
                      md={3}
                      className="mt-2 d-flex align-items-start"
                      key={country.iso}
                    >
                      <img
                        src={`https://flagcdn.com/32x24/${country.iso.toLowerCase()}.png`}
                        width="22"
                        height="18"
                        alt={`${country.iso} flag`}
                        className="mt-1"
                      />
                      &nbsp;
                      <span className="country-names">{countryName}</span>
                    </Col>
                  );
                })}
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col className="m-0 p-0" md={12} xs={11}>
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OfferDetail;
