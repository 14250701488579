import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";
import { countryList } from "../constants/countryList";

const HomeSelect = ({ setSelectedCountry, country }) => {
  const { t, i18n } = useTranslation();
  const [getCountries, setGetCountries] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [countrys, setCountry] = useState([]);
  const { setSelectData, setSelectDays, setSelectPrice } =
    useContext(AuthUserContext);

  const getCountry = async () => {
    try {
      setCountry(countryList);
      if (countryList) {
        const array = countryList?.map((item) => ({
          label: t(`countryName.${item?.name?.common}`) || "",
          value: item?.name?.common || "",
          flag: item?.flags?.png || "",
          aliases: item?.name?.common === "Saudi Arabia" 
              ? ["Hajj", "Umrah"] 
              : item?.name?.common === "Iraq" 
              ? ["Ziyarat"] 
              : [],
      }));
      
        setGetCountries(array);
        findCountryByCca2(countryList);
      }
    } catch (e) {
      console.log("Error fetching countries:", e);
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectData("");
    setSelectPrice("");
    setSelectDays("");
    const labels = selectedOptions?.map((option) => option.value);
    let selectedCountry = "";
    countrys?.forEach((country) => {
      if (country?.name?.common === labels[labels.length - 1]) {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("country", country.cca2);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({ path: newUrl }, "", newUrl);
        selectedCountry = country.cca2;
      }
    });

    setSelectedCountry(selectedCountry);
    setSelectedValues(selectedOptions);
  };

  const findCountryByCca2 = (countries) => {
    const array = countries?.reduce((acc, country) => {
      if (country?.cca2 === country) {
        acc.push({
          label: t(`countryName.${country?.name?.common}`),
          value: country?.name?.common,
          flag: country?.flags?.png,
        });
      }
      return acc;
    }, []);
    setSelectedValues(array);
  };

  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "start",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      direction: i18n.language === "ar" ? "rtl" : "ltr",
    }),
    control: (provided) => ({
      ...provided,
      direction: i18n.language === "ar" ? "rtl" : "ltr",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "white",
      caretColor: "white",
    }),
  };

  const customFilterOption = (option, inputValue) => {
    const label =
      option.label && typeof option.label === "string"
        ? option.label.toLowerCase()
        : "";
    const value =
      option.value && typeof option.value === "string"
        ? option.value.toLowerCase()
        : "";
    const aliases = Array.isArray(option.data.aliases)
      ? option.data.aliases.map((alias) => alias.toLowerCase())
      : [];
    return (
      label.includes(inputValue.toLowerCase()) ||
      value.includes(inputValue.toLowerCase()) ||
      aliases.some((alias) => alias.includes(inputValue.toLowerCase()))
    );
  };
  useEffect(() => {
    getCountry();
  }, [i18n.language]);

  const handleSelectFocus = () => {
    setIsFocused(true);
  };

  const handleSelectBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    findCountryByCca2(countrys);
  }, [getCountries, country]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div>
      <Select
        isMulti
        options={getCountries}
        styles={customStyles}
        getOptionLabel={(option) => (
          <div style={{ display: "flex", alignItems: "start" }}>
            <img
              src={option.flag}
              alt={option.label}
              style={{
                width: "35px",
                height: "22px !important",
                marginRight: "5px",
              }}
            />
            <span>{option.label}</span>
          </div>
        )}
        value={selectedValues}
        onChange={handleSelectChange}
        onFocus={handleSelectFocus}
        onBlur={handleSelectBlur}
        onKeyDown={handleKeyDown}
        placeholder={!isFocused ? t("home.part26") : ""}
        // menuIsOpen={isFocused}
        filterOption={customFilterOption}
      />
    </div>
  );
};

export default HomeSelect;
