import React, { useRef, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = ({ description, setDescription, localization }) => {
  const editorRef = useRef(null);
  const [key, setKey] = useState(Date.now());

  const handleEditorChange = (content) => {
    if (content === "") content = "  ";
    setDescription(content);
  };

  useEffect(() => {
    setKey(Date.now());
  }, [localization]);
  return (
    <div className="mt-4">
      <Editor
        key={key}
        apiKey="x1vg019oywqyjxcxpau7lazwy8m509ac1lsb8vhks1tk7ycb"
        onInit={(editor) => (editorRef.current = editor)}
        value={description}
        onEditorChange={handleEditorChange}
        init={{
          height: 250,
          plugins:
            "preview importcss searchreplace autolink autosave directionality code visualblocks visualchars fullscreen image link codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons alignleft aligncenter alignright alignjustify",
          menubar: false,
          toolbar:
            "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media template link anchor codesample | ltr rtl",
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          toolbar_mode: "sliding",
          language:
            localization === "indonesian"
              ? "id"
              : localization === "french"
              ? "fr_FR"
              : localization === "arabic"
              ? "ar"
              : "en",
              // statusbar: false
        }}
      />
    </div>
  );
};

export default TextEditor;
