import React from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const PasswordInput = (props) => {
  return (
    <div>
      <label className={props.labelClassName}>
        {props.label} <span className="text-danger-asterisk">*</span>
      </label>
      <div className={props.className}>
        <input
          type={props.showPassword ? "text" : "password"}
          name={props.name}
          placeholder={props.placeholder}
          className={`${
            props.currentLocalization == "ar" ? "offdK-right" : "offdK"
          }`}
          onChange={props.onChange}
          required={props.required}
          value={props.value}
          autoComplete="new-password"
        />
        <button className="btn" onClick={props.handleTogglePassword}>
          {props.showPassword ? (
            <FaEye size={20} color="#C8C8C8" />
          ) : (
            <FaEyeSlash size={20} color="#C8C8C8" />
          )}
        </button>
      </div>
    </div>
  );
};

export default PasswordInput;
