import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import SectionMode from "./SectionMode";
import { IoClose } from "react-icons/io5";
import { AuthUserContext } from "../context/context";
import Spinner from "react-bootstrap/Spinner";
import { images } from "../utils/image";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import HomeSelect from "./HomeSelect";
import { countryList } from "../constants/currency";
import Footer from "./Footer/Footer";
import getEsimLists from "../services/api/eSim/getEsimDetails";
import OfferCard from "./OfferCard";
import call from "../assets/images/call.svg";
import address from "../assets/images/address.svg";
import email from "../assets/images/email.svg";
const PreViewModal = ({
  preModeModalShow,
  setPreModeModalShow,
  preModeModalLoading,
  allSectionWithLang,
  buttonTabs,
}) => {
  const { currentLocalization, user } = useContext(AuthUserContext);
  const { t } = useTranslation();
  const [eSimDetails, setEsimDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState();
  const getEsimList = async () => {
    try {
      setLoading(true);
      let langs;
      if (currentLocalization === "en-US") {
        langs = "en";
      } else {
        langs = currentLocalization;
      }
      const response = await getEsimLists("", "", "", "", "", langs);
      setEsimDetails(response?.data);
    } catch (err) {
      console.log("e", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (buttonTabs === "Home") getEsimList();
  }, [currentLocalization]);
  return (
    <div>
      {preModeModalShow ? (
        <Modal
          show={preModeModalShow}
          dialogClassName=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="preview-Modal"
          style={{margin: "0"}}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter" className=" w-100">
              <div
                className={`d-flex justify-content-between align-items-center w-100 ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
                  }`}
              >
                <h4 className="modal-h4 text-center mt-1">
                  {t(`${buttonTabs} Preview Mode`)}
                </h4>
                <IoClose
                  onClick={() => setPreModeModalShow(false)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            {buttonTabs === "Home" && (
              <>
                <section className="home-image">
                  <Container>
                    <Row className="menu-padding d-flex justify-content-center align-items-center ">
                      <Col md={12} xs={12} className="menu-box p-md-5">
                        <Row
                          className={
                            currentLocalization == "ar"
                              ? "d-flex flex-row-reverse align-items-center p-5"
                              : "d-flex flex-row align-items-center"
                          }
                        >
                          <Col
                            lg={7}
                            md={8}
                            xs={12}
                            className={
                              currentLocalization == "ar"
                                ? "text-end p-3"
                                : "text-start p-3"
                            }
                          >
                            <span className="menu-box-span">
                              <img src={images.line} alt="line" />
                              &nbsp;&nbsp;&nbsp; {t("home.part1")}
                            </span>
                            <p className="menu-box-p-unleash m-0">
                              {t("home.part2")}
                            </p>
                            <h3 className="menu-box-p-technology m-0">
                              {t("home.part3")}
                              <span style={{ color: "#2AFC9E" }}>
                                {" "}
                                {t("home.part4")}
                              </span>
                              <span style={{ color: "#fff" }}>
                                {t("home.part5")}
                              </span>
                            </h3>
                            <h3 className="menu-box-p-technology">
                              {t("home.part6")}
                            </h3>
                            <Row
                              className={
                                currentLocalization == "ar"
                                  ? "d-flex justify-content-end"
                                  : ""
                              }
                            >
                              <Col md={10} xs={12} className="pt-2">
                                <label className="menu-box-label-country mb-3">
                                  {t("home.part7")}
                                </label>
                                <HomeSelect
                                  setSelectedCountry={setSelectedCountry}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={5} md={4} xs={12}>
                            {currentLocalization == "ar" ? (
                              <img
                                src={images.esimAr}
                                alt="mobile"
                                className="img-fluid"
                                height={455}
                              />
                            ) : currentLocalization == "fr" ? (
                              <img
                                src={images.esimFr}
                                alt="mobile"
                                className="img-fluid"
                                height={455}
                              />
                            ) : currentLocalization == "in" ? (
                              <img
                                src={images.esimIn}
                                alt="mobile"
                                className="img-fluid"
                                height={455}
                              />
                            ) : (
                              <img
                                src={images.mobile}
                                alt="mobile"
                                className="img-fluid"
                                height={455}
                              />
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </section>
                <section className="pt-5 country-image-box">
                  <Container>
                    <Row className="d-flex justify-content-center">
                      <div className="d-flex justify-content-center text-center">
                        <Col
                          lg={
                            currentLocalization == "fr" ||
                              currentLocalization == "in"
                              ? 8
                              : 6
                          }
                          md={4}
                          xs={12}
                        >
                          <h2 className="popular-h2">
                            {t("home.part9")}
                            <span style={{ color: " #004940" }}>
                              {t("home.part29")}
                            </span>
                          </h2>
                        </Col>
                      </div>
                      <Col
                        lg={
                          currentLocalization == "fr" ||
                            currentLocalization == "in"
                            ? 9
                            : 7
                        }
                        md={5}
                        xs={12}
                        className="text-center"
                      >
                        <span className="home-time-span">
                          {t("home.part10")}
                        </span>
                        <br />
                        <span className="home-time-span">
                          {t("home.part31")}
                        </span>
                      </Col>
                      <Row className="d-flex justify-content-center">
                        <Col md={12} className="grid-box mt-5 mb-4 ">
                          {countryList?.map((item, index) => {
                            return (
                              <div className="country-box m-2 mb-4" key={index}>
                                <img
                                  src={item?.flag}
                                  alt="flag"
                                  className="country-image"
                                />
                                <span className="country-name-span mt-3">
                                  {t(`countryName.${item?.countryName}`)}
                                </span>
                              </div>
                            );
                          })}
                        </Col>
                      </Row>

                      <Col xs={12} className="d-flex justify-content-center">
                        <button className="view-btn mb-5 ">
                          {t("home.part11")}
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </>
            )}
            {
              buttonTabs === "Contact Us" && (
                <section className="country-image-box">
        <Container>
          <Row
            
            className={`pb-4 pt-lg-5 d-flex ${
              currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
            }`}
          >
            <Col
              md={6}
              className={`mt-3  ${
                currentLocalization === "ar" ? "text-end" : "text-start"
              }`}
            >
              <h2 className="get-in-h2">
                <span>{t("contactUs.Get In")}</span>&nbsp;
                <span style={{ color: "#004940" }}>
                  {t("contactUs.Touch_")}
                </span>
              </h2>
              <p className="contact-message-p mt-4">{t("contactUs.message")}</p>

                        <p
                          className={`contact-message-contact d-flex mt-4 ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
                            }`}
                        >
                          <img src={call} alt="call" />{" "}
                          <span
                            className={currentLocalization === "ar" ? "pe-4" : "ps-4"}
                          >
                            +44 7766 540 364
                          </span>
                        </p>
                        <p
                          className={`contact-message-contact d-flex mt-4 ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
                            }`}
                        >
                          <img src={email} alt="email" />
                          <span
                            className={currentLocalization === "ar" ? "pe-4" : "ps-4"}
                          >
                            info@gleesim.com
                          </span>
                        </p>
                        <p
                          className={`contact-message-contact d-flex mt-4 ${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
                            }`}
                        >
                          <img src={address} alt="address" />
                          <span
                            className={currentLocalization === "ar" ? "pe-4" : "ps-4"}
                          >
                            Unit 4 All Cool building, 1st Floor Ripple Side commercial estate, A13
                            <br /> Barking, Greater London IG11 0RJ
                          </span>
                        </p>
                      </Col>
                      <Col
                        md={6}
                        className={`mt-3 d-flex ${currentLocalization === "ar"
                            ? "justify-content-start"
                            : "justify-content-md-end"
                          }`}
                      >
                        <form
                          className={`message-box text-start p-4 ${currentLocalization === "ar" ? "text-end" : "text-start"
                            }`}
                        // onSubmit={handleSubmit}
                        >
                          <h2 className="send-message-h2">
                            {t("contactUs.Send a Message")}
                          </h2>
                          <div className="d-flex flex-column mt-5">
                            <label className="label-text">
                              {t("signUp.part3")}{" "}
                              <span className="text-danger-asterisk">*</span>
                            </label>
                            <input
                              type="text"
                              name="name"
                              placeholder={t("signUp.part4")}
                              className={` mt-2 ${currentLocalization === "ar"
                                  ? "name-input-right"
                                  : "name-input"
                                }`}
                            />
                          </div>
                          <div className="d-flex flex-column mt-4">
                            <label className="label-text">
                              {t("contactUs.Email")}{" "}
                              <span className="text-danger-asterisk">*</span>
                            </label>
                            <input
                              type="email"
                              name="email"
                              placeholder={t("contactUs.Enter Email")}
                              className={` mt-2 ${currentLocalization === "ar"
                                  ? "name-input-right"
                                  : "name-input"
                                }`}
                            />
                          </div>
                          <div className="d-flex flex-column mt-4">
                            <label className="label-text">
                              {t("contactUs.Message")}{" "}
                              <span className="text-danger-asterisk">*</span>
                            </label>
                            <textarea
                              name="message"
                              placeholder={t("contactUs.EnterMessage")}
                              className={`mt-2 ${currentLocalization === "ar"
                                  ? "name-textarea-right"
                                  : "name-textarea"
                                }`}
                              rows="4"
                            />
                          </div>
                          <div
                            className={`d-flex ${currentLocalization === "ar"
                                ? "justify-content-start"
                                : "justify-content-end"
                              }`}
                          >
                            <button
                              type="submit"
                              className="submit-btn mt-4"
                              disabled={loading}
                            >
                              {t("otpVerification.part4")}
                            </button>
                          </div>
                        </form>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )
            }
            <div className={buttonTabs !== "Home" && "offers-image"}>
              {preModeModalLoading ? (
                <div
                  className="w-100 d-flex justify-content-center align-items-center"
                  style={{ height: "400px" }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <div className="pt-5 pb-5">
                  <SectionMode allSectionWithLang={allSectionWithLang} />
                </div>
              )}
              {buttonTabs === "Home" ? (
                <section className="offers-image">
                  <Container>
                    <Row className="d-flex justify-content-center pt-5 m-0">
                      <Col lg={12} md={10} xs={12} className="pt-3 text-center">
                        <h2 className="popular-h2">
                          {t("home.part12")}
                          &nbsp;
                          <span style={{ color: " #004940" }}>
                            {t("home.part30")}
                          </span>
                        </h2>
                        <span className="home-time-span">
                          {t("home.part13")}
                        </span>
                        <br />
                        <span className="home-time-span">
                          {t("home.part17")}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mt-5 ">
                      <Col
                        xs={12}
                        className="d-flex flex-wrap justify-content-between"
                      >
                        {loading ? (
                          <div className="w-100 pt-5 pb-5">
                            <Spinner animation="border" variant="dark" />
                          </div>
                        ) : eSimDetails?.length > 0 ? (
                          <>
                            {eSimDetails
                              ?.slice(
                                eSimDetails?.length - 6,
                                eSimDetails?.length
                              )
                              .map((item, index) => {
                                return (
                                  <OfferCard
                                    item={item}
                                    index={index}
                                    offers={eSimDetails}
                                    exchangeRate={1}
                                    user={user}
                                    currencySymbol={"$"}
                                    previewMode="previewMode"
                                  />
                                );
                              })}
                          </>
                        ) : (
                          <div className="w-100 pt-5 pb-5">
                            <span>{t("home.part28")}</span>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Col xs={12} className="d-flex justify-content-center">
                      <button
                        className={
                          loading ? "view-btn-disabled mb-5" : "view-btn mb-5"
                        }
                      >
                        {t("home.part11")}
                      </button>
                    </Col>

                    <Footer />
                  </Container>
                </section>
              ) : (
                <Container className="mt-5">
                  <Footer />
                </Container>
              )}
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PreViewModal;
