import React, { useContext } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";
const DataTables = ({ columns, data, loading, handleSort }) => {
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);
  return (
    <div className="datatable-width-set">
      <DataTable
        columns={columns}
        data={data}
        fixedHeaderScrollHeight="300px"
        highlightOnHover
        noContextMenu
        responsive={true}
        selectableRowsRadio="radio"
        pagination
        subHeaderAlign="center"
        direction={currentLocalization == "ar" ? "rtl" : "ltr"}
        progressPending={loading}
        onSort={handleSort}
        progressComponent={
          loading ? (
            <div
              className=" d-flex justify-content-center align-items-center"
              style={{ height: "400px" }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <div
              className=" d-flex justify-content-center align-items-center border"
              style={{ height: "400px" }}
            >
              {t("dataUsage.noRecordsFound")}
            </div>
          )
        }
        noDataComponent={
          loading ? (
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "400px" }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "400px" }}
            >
              {t("dataUsage.noRecordsFound")}
            </div>
          )
        }
      />
    </div>
  );
};

export default DataTables;
