import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "../page/home";
import OfferDetail from "../page/offer-details";
import Navbars from "../component/Navbar/Navbar";
import CountryFilter from "../page/country-filter";
import { AuthUserContext } from "../context/context";
import { ToastContainer, toast } from "react-toastify";
import Login from "../page/login";
import SignUp from "../page/signUp";
import NewPassword from "../page/newPassword";
import OtpVerification from "../page/otpVerification";
import ResetPassword from "../page/resetPassword";
import Sidebar from "../component/Sidebar/SideBar";
import MyPackage from "../page/myPackage";
import DataUsage from "../page/dataUsage";
import UserListing from "../page/usersListing";
import ProfileSetting from "../page/profile-setting";
import PrivateComponent from "../PrivateComponent";
import TopUp from "../page/topUp";
import { USER_DETAILS } from "../constants/localstorageKeys";
import PackagesListing from "../page/package-listing";
import TopUpListing from "../page/topup-listing";
import { Link, useNavigate } from "react-router-dom";
import TransactionsListing from "../page/transactions-listing";
import ContentManagement from "../page/content-management";
import ContactUs from "../page/contactUs";
import AboutUs from "../page/about-us";

const MainRoute = () => {
  const { currentLocalization, setToastId, toastId } =
    useContext(AuthUserContext);
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const [toastClose, setToastClose] = useState(false);
  const [eventTriggered, setEventTriggered] = useState(false);
  const authRoute = [
    "/log-in",
    "/sign-up",
    "/otp-verification",
    "/reset-password",
    "/new-password",
  ];
  useEffect(() => {
    if (userDetails) {
      setUser(userDetails);
      if (authRoute.includes(pathname)) {
        if (userDetails.role === "admin") {
          navigate(`/users-listing`);
        } else {
          navigate(`/data-usage`);
        }
      }
    }
  }, []);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const refParam = urlParams.get("ref");
    if (refParam) {
      if (userDetails) {
        navigate(`/data-usage`);
      } else {
        navigate(`/sign-up?ref=${refParam}`);
      }
    }
  }, []);

  const handleEvent = () => {
    if (toastId) {
      setEventTriggered(true);
    }
  };
  useEffect(() => {
    if (eventTriggered && toastId) {
      setEventTriggered(false);
      toast.update(toastId, {
        autoClose: 1000,
        progress: undefined,
      });
      const timer = setTimeout(() => {
        setEventTriggered(false);
        setToastId(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [eventTriggered, toastId]);
  return (
    <div onClick={handleEvent}>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        rtl={currentLocalization === "ar" ? true : false}
        draggable
        pauseOnHover
        className={currentLocalization === "ar" ? "text-end" : "text-start"}
      />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/log-in"
          element={<Login setEventTriggered={setEventTriggered} />}
        />
        <Route
          path="/sign-up"
          element={<SignUp setEventTriggered={setEventTriggered} />}
        />
        <Route
          path="/otp-verification"
          element={<OtpVerification setEventTriggered={setEventTriggered} />}
        />
        <Route
          path="/reset-password"
          element={<ResetPassword setEventTriggered={setEventTriggered} />}
        />
        <Route
          path="/new-password"
          element={<NewPassword setEventTriggered={setEventTriggered} />}
        />
        <Route path="/offer-detail" element={<OfferDetail />} />
        <Route path="/country-filter" element={<CountryFilter />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route element={<PrivateComponent />}>
          <Route path="/my-package" element={<MyPackage />} />
          <Route path="/topup-listing" element={<TopUpListing />} />
          <Route
            path="/transactions-listing"
            element={<TransactionsListing />}
          />
          <Route path="/data-usage" element={<DataUsage />} />
          <Route path="/package-listing" element={<PackagesListing />} />
          <Route path="/users-listing" element={<UserListing />} />
          <Route path="/profile-setting" element={<ProfileSetting />} />
          <Route
            path="/top-up"
            element={<TopUp setEventTriggered={setEventTriggered} />}
          />
          <Route path="/content-management" element={<ContentManagement />} />
        </Route>
      </Routes>
    </div>
  );
};

export default MainRoute;
